/**
 * Copyright ©2022 Drivepoint
 */

import {IconButton, IconButtonProps, styled} from "@mui/material";
import constants from "../../configuration/constants";

const DrawerToggleButton = styled(IconButton)<IconButtonProps>(({theme}: {theme: any}) => {
  return {
    height: "100%",
    width: `${constants.drawers.navigation.collapsedWidth}`,
    borderRadius: "0",
    backgroundColor: theme.palette.chrome.primary.background
  };
});

export default DrawerToggleButton;
