/**
 * Copyright ©2023 Drivepoint
 */

import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {EmailAuthProvider, GoogleAuthProvider, User} from "firebase/auth";
import firebase from "firebase/compat/app";
import AdditionalUserInfo = firebase.auth.AdditionalUserInfo;
import jwtDecode from "jwt-decode";
import {Config} from "@bainbridge-growth/node-frontend";
import ErrorDialog, {ErrorDialogInterface} from "@components/ErrorDialog";
import Firebase from "@services/firebase/Firebase";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import email from "../../../../assets/images/email.svg";
import "firebaseui/dist/firebaseui.css";
import "./BainbridgeAuthentication.less";

export type BainbridgeAuthenticationProps = {
  mode: "sign_up" | "sign_in";
  companyId?: string;
  state?: any;
  invitation?: any;
  onSuccess: (excelUser: any) => void;
  onFailure: (error: any) => void;
};

export default function BainbridgeAuthentication(props: BainbridgeAuthenticationProps): any {

  const errorDialog = useRef<ErrorDialogInterface>();
  const navigate = useNavigate();
  const state = useRef<any>();
  const invitation = useRef<any>();
  const [busy, setBusy] = useState<boolean>(false);
  const [wrongSignInEmail, setWrongSignInEmail] = useState<boolean>(false);

  const [container, setContainer] = useState<any>();

  useEffect(() => {
    if (props.mode === "sign_in") { Firebase.autoSetUpUser = false; }
    return () => {
      if (props.mode === "sign_in") { Firebase.autoSetUpUser = true; }
    };
  }, []);

  useEffect(() => {
    if (props.state) {
      state.current = jwtDecode(props.state);
    }
    invitation.current = props.invitation;
  }, [props]);

  useEffect(() => {
    if (!container) { return; }
    const fullLabelPrefix = props.mode === "sign_in" ? "Sign in with " : "Sign up with ";
    Firebase.ui.start(container, {
      signInOptions: [
        {provider: GoogleAuthProvider.PROVIDER_ID, customParameters: {prompt: "select_account"}, fullLabel: `${fullLabelPrefix}Google`},
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
          buttonColor: "white",
          fullLabel: `${fullLabelPrefix}Email`,
          iconUrl: email
        }
      ],
      signInFlow: "popup",
      tosUrl: "https://www.drivepoint.io/terms/terms-of-service",
      privacyPolicyUrl: "https://www.drivepoint.io/terms/privacy-policy",
      callbacks: {
        signInSuccessWithAuthResult: onFirebaseSignInSuccess,
        uiShown: onUIShown
      }
    });

    return () => {
      document.getElementsByClassName("firebaseui-id-submit")[0]?.removeEventListener("click", handleListenerForSignInNextButton);
      document.getElementsByClassName("firebaseui-idp-button")[1]?.removeEventListener("click", handleListenerForSignInButton);
    };

  }, [container]);

  async function handleListenerForSignInNextButton() {
    const emailelement = document.getElementById("ui-sign-in-email-input") as HTMLInputElement;
    const isExist = await Firebase.emailExistAlready(emailelement.value);
    if (!isExist) {navigate("/quickstart", {replace: true});}
  }

  async function handleListenerForSignInCancelButton() {
    document.getElementsByClassName("firebaseui-id-submit")[0]?.removeEventListener("click", handleListenerForSignInNextButton);
    document.getElementsByClassName("firebaseui-idp-button")[1]?.removeEventListener("click", handleListenerForSignInButton);
    const idpButton = document.getElementsByClassName("firebaseui-idp-button")[1];
    if (idpButton) {
      idpButton.addEventListener("click", handleListenerForSignInButton);
    }

  }

  function handleListenerForSignInButton(event: any) {
    document.getElementsByClassName("firebaseui-id-submit")[0]?.addEventListener("click", handleListenerForSignInNextButton);
    document.getElementsByClassName("firebaseui-id-secondary-link")[0]?.addEventListener("click", handleListenerForSignInCancelButton);

  }

  function onUIShown(){
    const idpButton = document.getElementsByClassName("firebaseui-idp-button")[1];
    if (idpButton && !location.pathname.includes("/invite_user")) {
      idpButton.addEventListener("click", handleListenerForSignInButton);
    }
  }

  function onFirebaseSignInSuccess(result: any): boolean {
    const user: User = result.user;
    const additionalUserInfo: AdditionalUserInfo = result.additionalUserInfo;
    switch (props.mode) {
      case "sign_in":
        handleSignInSuccess(user, additionalUserInfo);
        break;
      case "sign_up":
        handleSignUpSuccess(user, additionalUserInfo);
        break;
    }
    return false;
  }

  async function handleSignInSuccess(user: User, additionalUserInfo: AdditionalUserInfo): Promise<void> {
    if (additionalUserInfo.isNewUser) {
      await removeInvalidUser(additionalUserInfo);
      return;
    }
    props.onSuccess(user);
  }

  async function removeInvalidUser(additionalUserInfo: AdditionalUserInfo): Promise<any> {
    try {
      await WebAppServerClient.request("/ui/auth", "DELETE", additionalUserInfo);
      showError(new Error("Account does not exist, please sign up before attempting to sign in. If you are joining an existing company team at Drivepoint, please request an invitation from that team."));
    } catch (error: any) {
      const message = error.response?.errors?.[0]?.error || error.message;
      logger.error(message);
      showError(new Error(message));
    }
  }

  async function handleSignUpSuccess(user: User, additionalUserInfo: AdditionalUserInfo): Promise<void> {
    try {
      setBusy(true);
      const excelUser = await createExcelUser(user);
      props.onSuccess(excelUser);
    } catch (error: any) {
      logger.error(error.message);
      showError(error);
    } finally {
      setBusy(false);
    }
  }

  async function createExcelUser(user: User): Promise<any> {
    Firebase.token = await user.getIdToken();
    const companyId = props.companyId || invitation.current?.company?.id || "";
    const [firstName, ...lastName] = (user?.displayName?.split(" ") || []).filter((it: any) => it);
    const payload: any = {...user, firstName, lastName: lastName.join(" "), excelCompanyId: companyId, createState: state.current};
    const excelUser = await WebAppServerClient.request("/ui/excelUser/quickstart", "POST", payload);
    await Firebase.reload();
    return excelUser;
  }

  function showError(error: any): void {
    logger.error(error.message);
    let message = error.message || "An unknown error occurred while attempting to create your account.";
    if (error.code === 409) { // Duplicate Firebase user
      message = "Sorry - an account already exists with this email. Try signing in instead.";
    }
    const body = <div style={{display: "flex", flexDirection: "column", gap: "0.5em"}}>
      <div>{message}</div>
      <div>Please contact <a href={Config.get("urls.customer_support")}>Drivepoint Support</a> for assistance if the issue persists.</div>
    </div>;
    errorDialog?.current?.show(body, () => {
      Firebase.signOut().then(() => window.location.reload());
    }, "Oh no!");
  }

  return <div className="bainbridge-authentication">
    <ErrorDialog ref={errorDialog} />
    <div className="busy" style={{visibility: busy ? "visible" : "hidden"}}><CircularProgress /></div>
    <div className="sign-in-container" ref={ref => setContainer(ref)} />
    <div className="bainbridge-authentication-google-api-disclosure">
      Drivepoint's use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
    </div>
  </div>;

}
