/**
 * Copyright ©2024 Drivepoint
 */

import "reflect-metadata";

export enum DazzlerMethodGroupName {
  GENERAL = "General",
  FORMATTERS = "Formatters",
  PIVOTS = "Pivots",
  INTELLIGENCE = "Intelligence"
}

export const DazzlerMethodGroup = (group: DazzlerMethodGroupName): any => {
  return (target: any, propertyKey: string): void => {
    let documentation: any = {};
    if (Reflect.hasMetadata("__DAZZLER_DOCUMENTATION__", target)) {
      documentation = Reflect.getMetadata("__DAZZLER_DOCUMENTATION__", target) as any;
    }
    if (!documentation[propertyKey]) { documentation[propertyKey] = {}; }
    documentation[propertyKey].namespace = target.NAMESPACE;
    documentation[propertyKey] = {...documentation[propertyKey], group};
    Reflect.defineMetadata("__DAZZLER_DOCUMENTATION__", documentation, target);
  };
};
