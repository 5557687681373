/**
 * Copyright ©2023 Drivepoint
 */

import {Event} from "../eventbus/Event";

export default class FirebaseEvent extends Event {

  constructor(public type: string, public data: any) {
    super(type);
  }

}
