/**
 * Copyright ©2022 Drivepoint
 */

import {Link, LinkProps, styled} from "@mui/material";

const BrandLink = styled(Link)<LinkProps>(({theme}: {theme: any}) => {
  return {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.chrome.link.color,
    "&:hover": {
      color: theme.palette.chrome.link.hover
    }
  };
});

export default BrandLink;
