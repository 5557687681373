import React, {createContext, ReactElement, ReactNode, useState} from "react";

type DPErrorBoundaryProps = {
  children: ReactElement
  fallback: ReactNode
};

type DPErrorBoundaryContextProps = {
  onDPErrorBoundary: React.Dispatch<React.SetStateAction<Error | null>>;
};

export const DPErrorBoundaryContext = createContext<DPErrorBoundaryContextProps | null>(null);

export default function DPErrorBoundary({children, fallback}: DPErrorBoundaryProps) {

  const [error, setError] = useState<Error | null>(null);

  function render() {
    if (error) { logger.error(error.message); }
    return <DPErrorBoundaryContext.Provider value={{onDPErrorBoundary: setError}}>
      {children}
    </DPErrorBoundaryContext.Provider>;
  }

  return render();
}
