/**
 * Copyright ©2023 Drivepoint
 */

import {Mutex} from "async-mutex";
import localforage from "localforage";
import md5 from "md5";
import {ObjectUtilities} from "@bainbridge-growth/node-common";
import {EventBus} from "@services/eventbus/EventBus";
import Template from "../utilities/template/Template";
import WebAppServerClient from "./clients/WebAppServerClient";
import State from "./state/State";

export default class DazzlerDataManager {

  private static CACHE_TTL: number = 300000;
  private static _mutex: any = new Mutex();
  private static _forage: LocalForage = localforage.createInstance({name: "DazzlerDataManager"});

  static async init(): Promise<void> {
    EventBus.registerMany("dazzler:flush", DazzlerDataManager.clearCache);
  }

  static async request(path: string, method: string, body?: any, force?: boolean): Promise<any> {
    await DazzlerDataManager.age();
    return await DazzlerDataManager._mutex.runExclusive(async () => {
      const hash: string = md5(`${path}-${method}-${JSON.stringify(body)}`);
      if (!force) {
        const entry: any = await DazzlerDataManager._forage.getItem(hash);
        if (entry) {
          const ttl = Date.now() - entry.when;
          if (ttl < DazzlerDataManager.CACHE_TTL) { return entry.result; }
        }
      }
      const result = await WebAppServerClient.request(path, method, body);
      if (result != null) { DazzlerDataManager._forage.setItem(hash, {when: Date.now(), result}); }
      return result;
    });
  }

  static clearCacheForRequest(path: string, method: string, body?: any): void {
    const hash: string = md5(`${path}-${method}-${JSON.stringify(body)}`);
    DazzlerDataManager._forage.removeItem(hash);
  }

  static clearCache(): void {
    DazzlerDataManager._forage.clear();
  }

  static async age(): Promise<void> {
    const keys = await DazzlerDataManager._forage.keys();
    for (const key of keys) {
      const entry: any = await DazzlerDataManager._forage.getItem(key);
      if (entry) {
        const ttl = Date.now() - entry.when;
        if (ttl >= DazzlerDataManager.CACHE_TTL) {
          logger.debug(`clearing key ${key} from cache, ttl was ${ttl}ms`);
          await DazzlerDataManager._forage.removeItem(key);
        }
      }
    }
  }

  static getParsedDatasource(datasource: any, context: Object = {}): any {
    try {
      let error: any;
      datasource = ObjectUtilities.clone(datasource);
      datasource.data.query = Template.parseSQL(datasource.data.query, context);
      const match = datasource.data.query.match(/\${(.+?)}/g);
      if (match) {
        logger.debug("getParsedDatasource", datasource, context);
        error = "Template could not be resolved";
      }
      return {datasource, error};
    } catch (error: any) {
      return {datasource, error: error.message};
    }
  }

  static async getDatasourceResults(datasource: any, context: Object = {}): Promise<any> {
    const {datasource: parsedDatasource, error} = DazzlerDataManager.getParsedDatasource(datasource, context);
    if (error) { throw new Error(error); }
    const company = State.get("company");
    parsedDatasource.data.query = this.apply_dataset_reporting_override(company, parsedDatasource.data.query);
    return await DazzlerDataManager.request(`/api/company/${company.id}/dazzler/datasource`, parsedDatasource.data.method, parsedDatasource);
  }

  private static apply_dataset_reporting_override(company: any, query: string): string {
    if (company?.reporting_endpoint_override === "drivepoint_ecommerce") {
      query = query.replaceAll("{ENV}_executiveDashboard", "{ENV}_executiveDashboard_drivepoint_ecommerce").replaceAll("{ENV}_ltvReport", "{ENV}_ltvReport_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_customers", "{ENV}_bainbridgeAnalytics.full_customers_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_orders", "{ENV}_bainbridgeAnalytics.full_orders_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.cohorted_orders", "{ENV}_bainbridgeAnalytics.cohorted_orders_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_products", "{ENV}_bainbridgeAnalytics.full_products_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_transactions", "{ENV}_bainbridgeAnalytics.full_transactions_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_orders_line_items_", "{ENV}_bainbridgeAnalytics.full_orders_line_items_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.first_orders", "{ENV}_bainbridgeAnalytics.first_orders_drivepoint_ecommerce");
      query = query.replaceAll("{ENV}_bainbridgeAnalytics.full_customers", "{ENV}_bainbridgeAnalytics.full_customers_drivepoint_ecommerce");
    }
    return query;
  }

  static async getBigQueryResults(query: string, context: Object = {}): Promise<any> {
    const company = State.get("company");
    query = Template.parseSQL(query, context);
    query = this.apply_dataset_reporting_override(company, query);
    return await DazzlerDataManager.request(`/api/company/${company.id}/dazzler/bigquery`, "POST", {query});
  }

  static async getGenAIResult(body: any, force?: boolean): Promise<any> {
    const company = State.get("company");
    return await DazzlerDataManager.request(`/api/company/${company.id}/genai`, "POST", body, force);
  }

  static async streamGenAIResult(body: any, force?: boolean): Promise<any> {
    // TODO: this should create an EventSource and hand that back to the caller, who will listen for events
    const company = State.get("company");
    return await DazzlerDataManager.request(`/api/company/${company.id}/genai/stream`, "POST", body, force);
  }

  static convertObjectRowsToRowsAndColumns(results: any): {columns: any[], rows: any[]} {
    try {
      if (!results) { return {columns: [], rows: []}; }
      const columns = results
        .reduce((columns: string[], row: any) => [...new Set([...columns, ...Object.keys(row)])], [])
        .map((column: string) => ({field: column, sortable: false}));
      const rows = results
        .map((row: any, index: number) => ({id: index, ...row}));
      return {columns, rows};
    } catch (error: any) {
      logger.error(results, error.message);
      return {columns: [], rows: []};
    }
  }

}
