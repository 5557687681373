/**
 * Copyright ©2023 Drivepoint
 */

export default class DynamicImportService {

  static modules: any;

  static async init(): Promise<void> {
    // has to be an array of literal strings, since Vite does magic replacement here:
    DynamicImportService.modules = import.meta.glob([
      "/src/pages/ScorecardPage/ScorecardMetric/ScorecardMetricTable/renderers/**/*.tsx"
    ], {import: "default"});
  }

  static async load(path: string): Promise<any> {
    if (DynamicImportService.modules[path]) {
      return DynamicImportService.modules[path]();
    } else {
      throw new Error(`module for path "${path}" not found.`);
    }
  }

}
