/**
 * Copyright ©2023 Drivepoint
 */

import TemplateSQLLibrary from "./libraries/TemplateSQLLibrary";
import ServiceRegistry from "@services/ServiceRegistry";
import TemplateJSLibrary from "@utilities/template/libraries/TemplateJSLibrary";

export type TemplateRunOptions = {
  additionalLibraries?: any[];
  globals?: any;
};

export default class Template {

  static AsyncFunction = async function(): Promise<void> {}.constructor;

  static parse(template: string, data?: any, options: TemplateRunOptions = {}): any {
    if (data != null && typeof data === "string") { throw new Error("data must be object"); }
    const {declarations, parameters} = Template._getJavascriptConfiguration(data, options);
    const body: string[] = [
      ...declarations,
      "return `" + template + "`;"
    ];
    try {
      return Function(body.join("\n"))(...parameters);
    } catch (error: any) {
      return template;
    }
  }

  static parseSQL(template: string, data?: any, globals?: any): any {
    data = {$CONTEXT: data};
    return Template.parse(template, data, {additionalLibraries: [TemplateSQLLibrary], globals: globals ?? {}});
  }

  static async run(code: string, data?: any, options: TemplateRunOptions = {}): Promise<any> {
    const {declarations, parameters} = Template._getJavascriptConfiguration(data, options);
    const body: string[] = [
      ...declarations,
      "",
      "$.__JS_ERROR__ = undefined;",
      "try {",
      "",
      ...code.split("\n"),
      "",
      "} catch(error) {",
      "  $LIB.JS.onError(error);",
      "}",
      "return $.data;"
    ];
    try {
      const results = await Template.AsyncFunction(body.join("\n"))(...parameters);
      const error = data.$.__JS_ERROR__;
      delete data.$.__JS_ERROR__;
      return {results, globals: data.$, error};
    } catch (error: any) {
      logger.debug(error);
      return {results: data, globals: data, error: {message: error.message, stack: error.stack}};
    }
  }

  private static _getJavascriptConfiguration(data: any, options: TemplateRunOptions): {declarations: string[], parameters: any[]} {
    const globals: any = {
      window: {},
      document: {},
      globalThis: {},
      $: options.globals ?? {},
      $FEATURES: ServiceRegistry.featureFlagService.featureFlags,
      ...data
    };
    const libraries: any[] = [TemplateJSLibrary, ...options.additionalLibraries ?? []];
    const functions: any[] = [];
    functions.push(...libraries.map(library => library.functions(globals))?.flat() ?? []);
    globals.$LIB = functions.reduce((functions: any, functionEntry: any) => {
      if (functionEntry.namespace) {
        if (!functions[functionEntry.namespace]) { functions[functionEntry.namespace] = {}; }
        functions[functionEntry.namespace][functionEntry.name] = functionEntry.fn;
      } else {
        functions[functionEntry.name] = functionEntry.fn;
      }
      return functions;
    }, {});
    const declarations: string[]  = Object.keys(globals).map((key, index) => `let ${key}=arguments[${index}];`);
    const parameters: any[] = Object.values(globals);
    return {declarations, parameters};
  }

}
