/**
 * Copyright ©2023 Drivepoint
 */

import React from "react";
import {BreadcrumbBarInterface} from "../components/Breadcrumbs/Breadcrumbs";

export type BreadcrumbItem = {
  id: string;
  label: string;
  pageId: string;
  icon?: any;
};

export default class BreadcrumbManager {

  static ref = React.createRef<BreadcrumbBarInterface>();

  static setPageLabel(id: string, label: string): void {
    BreadcrumbManager.ref.current?.setPageLabel(id, label);
  }

}
