/**
 * Copyright ©2022 Drivepoint
 */

export default class WebAppServerError extends Error {

  constructor(message: string, public endpoint: string, public response: any, public code: number) {
    super(message);
  }

}
