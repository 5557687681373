/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";
import ITelemetryProvider from "./ITelemetryProvider";
import Telemetry from "@services/telemetry/Telemetry";

/**
 * Segment-specific functionality.
 */

declare global {
  interface Window {Appcues: any}
}

export default class Appcues implements ITelemetryProvider {

  get name(): string { return "appcues"; }

  async embed(): Promise<void> {
    const script = document.createElement("script");
    script.src = `//fast.appcues.com/${Config.get("appcues.id")}.js`;
    document.head.append(script);
  }

  async identify(): Promise<void> {
    if (!window.Appcues || !Telemetry.user || !Telemetry.company) { return; }
    window.Appcues.identify(Telemetry.user.user.email, {firstName: Telemetry.user.excelUser?.firstName, companyName: Telemetry.company.id, email: Telemetry.user.user.email});
  }

  async track(event: string, data: any = {}): Promise<void> {
  }

}
