/**
 * Copyright ©2023 Drivepoint
 */

import {Utilities} from "@bainbridge-growth/node-common";
import {DazzlerMethodParameter} from "../../../decorators/dazzler/DazzlerMethodParameter";
import {DazzlerMethodGroup, DazzlerMethodGroupName} from "../../../decorators/dazzler/DazzlerMethodGroup";
import {DazzlerMethodAbstract} from "../../../decorators/dazzler/DazzlerMethodAbstract";

export default class TemplateSQLLibrary {

  static NAMESPACE: string = "SQL";

  static functions(globals: any): any[] {
    return [
      {name: "AND", fn: TemplateSQLLibrary.AND.bind(globals)},
      {name: "OR", fn: TemplateSQLLibrary.OR.bind(globals)}
    ].map(el => ({...el, namespace: TemplateSQLLibrary.NAMESPACE}));
  }

  static getSQLFormattedValue(value: any): string {
    if (typeof value === "string") { return `'${value}'`; }
    return `${value}`;
  }

  static getSQLValues(value: any): any {
    if (Array.isArray(value)) { return {type: "multiple", text: `(${value.map(TemplateSQLLibrary.getSQLFormattedValue).join(",")})`}; }
    return {type: "single", text: TemplateSQLLibrary.getSQLFormattedValue(value)};
  }

  static getSQLFilterClause(operand: string, id: string, value: any): string {
    const values = TemplateSQLLibrary.getSQLValues(value);
    if (values.type === "single") {
      switch (operand) {
        case ">":
        case ">=":
        case "<":
        case "<>":
        case "<=":
        case "=":
        case "==":
        case "!=":
        case "!==":
          if (operand == "==") { operand = "="; }
          if (operand == "!==") { operand = "!="; }
          if (operand == "<>") { operand = "!="; }
          return `[${id}] ${operand} ${values.text}`;
        default:
          logger.error(`unknown operation for single value: ${operand}, id: ${id}, value: ${value}`);
      }
    }
    if (values.type === "multiple") {
      switch (operand.toUpperCase()) {
        case "=":
        case "==":
        case "IN":
          return `[${id}] IN ${values.text}`;
        case "!=":
        case "!==":
        case "<>":
        case "NOT IN":
          return `[${id}] NOT IN ${values.text}`;
        default:
          logger.error(`unknown operation for multiple values: ${operand}, id: ${id}, value: ${value}`);
      }
    }
    return "";
  }

  static getOperatorClause(operator: string, operand: string, id: string, context: any): any {
    try {
      if (!operand || !id || !context) { return ""; }
      const value = context[id];
      if (Utilities.isEmpty(value)) { return ""; }
      return `${operator.toUpperCase()} ${TemplateSQLLibrary.getSQLFilterClause(operand, id, value)}`;
    } catch (error: any) {
      logger.debug(error.message);
    }
    return "";
  }

  @DazzlerMethodAbstract("Build an 'AND' clause with the operand and id.   '<', 'month' produces -> AND month < 'value for month' ", {returns: "any"})
  @DazzlerMethodParameter("operand", {description: "the comparator you're using >, <, =, !=", type: "any"})
  @DazzlerMethodParameter("id", {description: "id of the value you are comparing.", type: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static AND(operand: string, id: string): any {
    return TemplateSQLLibrary.getOperatorClause("AND", operand, id, (this as any).$CONTEXT);
  }

  @DazzlerMethodAbstract("Build an 'OR' clause with the operand and id.   '<', 'month' produces -> OR month < 'value for month' ", {returns: "any"})
  @DazzlerMethodParameter("operand", {description: "the comparator you're using >, <, =, !=", type: "any"})
  @DazzlerMethodParameter("id", {description: "id of the value you are comparing.", type: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static OR(operand: string, id: string): any {
    return TemplateSQLLibrary.getOperatorClause("OR", operand, id, (this as any).$CONTEXT);
  }

}
