/**
 * Copyright ©2022 Drivepoint
 */

import {darken} from "@mui/material";
import deepmerge from "deepmerge";

enum ThemeModes {
  LIGHT = "light",
  DARK = "dark"
}

const typography: any = {
  fontFamily: "Roboto",
  textTransform: "none"
};

const colors: any = {
  bbBlue: "#63AEFF",
  bbBlackText: "rgba(0, 0, 0, 0.87)",
  bbGrayText: "rgba(255, 255, 255, 0.75)"
};

const lightTheme: any = {
  chrome: {
    primary: {
      background: "#FFFFFF",
      color: colors.bbBlackText
    },
    link: {
      color: colors.bbBlue,
      hover: darken(colors.bbBlue, 0.2)
    },
    hover: {
      background: "rgba(0, 0, 0, 4%)"
    },
    selected: {
      background: "#63AEFF14"
    },
    divider: "rgba(0, 0, 0, 10%)"
  },
  content: {
    primary: {
      background: "#EEEEEE",
      color: colors.bbBlackText
    },
    secondary: {
      background: "#FFFFFF",
      color: colors.bbBlackText
    },
    border: "#CCCCCC"
  }
};

const themes: any = {
  [ThemeModes.LIGHT]: lightTheme,
  [ThemeModes.DARK]: deepmerge.all([lightTheme, {
    chrome: {
      primary: {
        background: "#101010",
        color: colors.bbGrayText
      },
      divider: "rgba(0, 0, 0, 75%)"
    }
  }])
};

export {ThemeModes, typography, themes};
