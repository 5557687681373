/**
 * Copyright ©2022 Drivepoint
 */

import FeatureFlagsService from "./featureflag/FeatureFlagsService";
import CompanyService from "./company/CompanyService";
import RouteService from "./route/RouteService";
import ServerSentEventService from "./serversentevents/ServerSentEventService";

export default class ServiceRegistry {

  private static _serverSentEventService: ServerSentEventService = new ServerSentEventService();
  private static _featureFlagService: FeatureFlagsService = new FeatureFlagsService();
  private static _companyService: CompanyService = new CompanyService();
  private static _routeService: RouteService = new RouteService();

  static async init(): Promise<any> {
    await this._serverSentEventService.init();
    await this._featureFlagService.init();
    await this._companyService.init();
    await this._routeService.init();
  }

  static get serverSentEventService(): ServerSentEventService {
    return this._serverSentEventService;
  }

  static get featureFlagService(): FeatureFlagsService {
    return this._featureFlagService;
  }

  static get companyService(): CompanyService {
    return this._companyService;
  }

  static get routeService(): RouteService {
    return this._routeService;
  }

}
