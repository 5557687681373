/**
 * Copyright ©2022 Drivepoint
 */

import {Box, BoxProps, styled} from "@mui/material";

const PageContent = styled(Box)<BoxProps>(({theme}: {theme: any}) => {
  return {
    overflow: "hidden",
    flexGrow: 1,
    position: "relative",
    backgroundColor: theme.palette.content.secondary.background,
    ".MuiPaper-root": {
      // backgroundColor: theme.palette.content.secondary.background
    }
  };
});

export default PageContent;
