/**
 * Copyright ©2023 Drivepoint
 */

import React from "react";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PrimaryIcon from "../../../../widgets/styled/PrimaryIcon";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import NavigationPageItem from "../NavigationPageItem/NavigationPageItem";
import "./NavigationSectionItem.less";

export type NavigationSectionItemProps = {
  item: any;
  currentSection?: any;
  currentPage?: any;
  open?: boolean;
  navigationStates?: string;
  onSectionContextMenu: (event: any, item: any) => void;
  toggleSection: (item: any) => void;
  onPageClick: (event: any, page: any) => void;
};

export default function NavigationSectionItem(props: NavigationSectionItemProps): any {

  function getNavigationStates(): any {
    if (props.navigationStates) { return JSON.parse(props.navigationStates); }
    if (localStorage.navigationStates) { return JSON.parse(localStorage.navigationStates); }
    return {};
  }

  function isSectionOpen(): boolean {
    if (!props.open) { return false; }
    const states = getNavigationStates();
    return states[props.item.name] ?? true;
  }

  function isSectionHighlighted(): boolean {
    if (props.item.name !== props.currentSection?.name) { return false; }
    if (!props.open) { return true; }
    return !isSectionOpen();
  }

  function renderPageList(): any {
    return <List className="navigation-section-item-list">
      {props.item.pages
        .map((page: any) => {
          if (page.hidden) { return; }
          return <NavigationPageItem
            key={`page_${page.id}`}
            item={page}
            open={props.open}
            currentPage={props.currentPage}
            onPageClick={props.onPageClick}
          />;
        })
        .filter((page: any) => page)}
    </List>;
  }

  function renderSectionPages(): any {
    if (props.item.canCollapse) {
      return <Collapse key={`collapse_${props.item.id}`} in={isSectionOpen()} timeout="auto">
        {renderPageList()}
      </Collapse>;
    } else {
      return renderPageList();
    }
  }

  function renderIcon(): any {
    if (!props.item.icon) { return <div className="navigation-section-header-item-icon" />; }
    return <ListItemIcon className="navigation-section-header-item-icon"><props.item.icon /></ListItemIcon>;
  }

  function renderCollapseIcon(): any {
    if (!props.item.canCollapse) { return; }
    return <PrimaryIcon className="navigation-section-header-item-control-icon">
      {isSectionOpen() ? <ExpandLess /> : <ExpandMore />}
    </PrimaryIcon>;
  }

  function renderSectionHeader(): any {
    if (props.item.name) {
      const classes = ["navigation-section-header"];
      if (isSectionHighlighted()) { classes.push("highlight"); }
      return <ListItemButton className={`${classes.join(" ")}`} disabled={!props.item.canCollapse}>
        {renderIcon()}
        <ListItemText className="navigation-section-header-item-text" primary={props.item.name} />
        {renderCollapseIcon()}
      </ListItemButton>;
    }
  }

  return <List
    className="navigation-section"
    onContextMenu={(event: any) => props.onSectionContextMenu(event, props.item)}
    onClick={() => props.toggleSection(props.item)}
  >
    {renderSectionHeader()}
    {renderSectionPages()}
  </List>;

}
