/**
 * Copyright ©2022 Drivepoint
 */

import "./init_globals"; // must be imported first to define window and global properties
import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import ModalProvider from "mui-modal-provider";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import DazzlerDataManager from "@services/DazzlerDataManager";
import App from "./main/App/App";
import Licenses from "./main/Licenses";
import LaunchDarklyClient from "./services/clients/LaunchDarklyClient";
import Telemetry from "./services/telemetry/Telemetry";
import Firebase from "./services/firebase/Firebase";
import ServiceRegistry from "./services/ServiceRegistry";
import GoogleCharts from "./services/google/GoogleCharts";
import DynamicImportService from "./services/DynamicImportService";
import "./index.css";
// import "./branding/test-company.css";

(async () => {

  dayjs.extend(quarterOfYear);

  await DazzlerDataManager.init();
  await DynamicImportService.init();
  Licenses.init();
  await LaunchDarklyClient.init();
  await Telemetry.init();
  await Firebase.init();
  await ServiceRegistry.init();
  await GoogleCharts.init();

  createRoot(document.getElementById("root")!).render(<BrowserRouter><ModalProvider><App /></ModalProvider></BrowserRouter>);

})();
