/**
 * Copyright ©2022 Drivepoint
 */

import {Paper, PaperProps, styled} from "@mui/material";

const PageContainer = styled(Paper)<PaperProps>(({theme}: {theme: any}) => {
  return {
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundImage: "none",
    borderRadius: "0",
    backgroundColor: theme.palette.content.secondary.background,
    "& .MuiDrawer-paper": {
      borderRadius: "0",
      backgroundImage: "none"
    }
  };
});

export default PageContainer;
