/**
 * Copyright ©2024 Drivepoint
 */

import {Store} from "@bainbridge-growth/node-frontend";

export default class ClientStatusStore extends Store {

  init(): void {
    this.loading = true;
    this.refresh();
    setInterval(() => this.refresh(), 180000);
  }

  async refresh(): Promise<void> {
    return this.action(async () => {
      this.value = await fetch(`${window.location.origin}/status.html?now=${Date.now()}`).then(it => it.json());
    });
  }

}
