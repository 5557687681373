/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import {Box, BoxProps, CircularProgress, styled} from "@mui/material";

const SuspendedContainer = styled(Box)<BoxProps>(({theme}: { theme: any }) => {
  return {
    position: "absolute",
    inset: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    zIndex: "2112"
  };
});

interface SuspendedProps {
  visible?: boolean;
}

export default function Suspended(props: SuspendedProps): any {

  function visible(): boolean {
    return props.visible !== undefined ? props.visible : true;
  }

  return <SuspendedContainer sx={{display: visible() ? "flex" : "none"}}>
    <CircularProgress />
  </SuspendedContainer>;

}
