/**
 * Copyright ©2024 Drivepoint
 */

import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {BugReport} from "@mui/icons-material";
import compareVersions from "compare-versions";
import {Config, useStore, Logger, LoggerLevels, useLocalStorage} from "@bainbridge-growth/node-frontend";
import {EventBus} from "@services/eventbus/EventBus";
import ClientStatusStore from "@services/store/ClientStatusStore";
import useStateChange from "@hooks/useStateChange";
import DrivepointUser from "@services/user/DrivepointUser";
import {LoggerLevelFromString} from "@bainbridge-growth/node-frontend/src/logger/LoggerLevel";
import ThemedDefaultButton from "../../widgets/styled/ThemedDefaultButton";
import "./AboutDialog.css";

export type AboutDialogProps = {
};

export type AboutDialogInterface = {
  show: () => void;
};

const AboutDialog = forwardRef<AboutDialogInterface, AboutDialogProps>(({}: AboutDialogProps, ref: ForwardedRef<AboutDialogInterface>): any => {

  useImperativeHandle(ref, (): AboutDialogInterface => ({
    show: () => {
      setOpen(true);
    }
  }));

  const defaultLoggerLevel = LoggerLevelFromString(Config.get("logger.level", "INFO"));

  const [clientStatus] = useStore<any, ClientStatusStore>(ClientStatusStore);

  const user = useStateChange<DrivepointUser>("user");

  const [loggerLevel, setLoggerLevel] = useLocalStorage<number>(`${Config.get("environment")}.logger.level`, defaultLoggerLevel);

  const [open, setOpen] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<any>();

  useEffect(() => {
    const registration = EventBus.register("server:status", setServerStatus);
    return () => {
      EventBus.unregister(registration);
    };
  }, []);

  function enableDebugMode(): void {
    Logger.level = Logger.level === LoggerLevels.TRACE ? defaultLoggerLevel : LoggerLevels.TRACE;
    const level = Object.entries(LoggerLevels).map(entry => entry[1] === Logger.level ? entry[0] : undefined).filter(it => it)[0];
    if (Logger.level === defaultLoggerLevel) {
      console.log(`logger level set back to ${level}`);
    } else {
      console.log(`logger level set to ${level}`);
    }
    setLoggerLevel(Logger.level);
  }

  function renderProductVersion(name: string, value: any, hash: string): any {
    return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <div style={{width: "8rem", textAlign: "right", marginRight: "1rem", color: "#404040"}}>{name}:</div>
      <div style={{width: "5rem"}}>{value}</div>
      <div style={{fontSize: "0.75rem", color: "#808080", marginLeft: "1rem"}}>{hash}</div>
    </div>;
  }

  function renderClientStatus(): any {
    if (!clientStatus?.version) { return; }
    if (compareVersions(clientStatus.version, Config.get("version")) <= 0) { return; }
    return renderProductVersion("Latest Web App", `${clientStatus?.version ? `v${clientStatus.version}` : "(N/A)"}`, clientStatus?.hash ? clientStatus.hash : "(N/A)");
  }

  function renderDebugButton(): any {
    if (user?.excelUser?.role !== "superAdmin") { return; }
    if (defaultLoggerLevel === LoggerLevels.TRACE) { return; }
    const color = loggerLevel === defaultLoggerLevel ? "default" : "primary";
    return <IconButton aria-label="delete" color={color} onClick={enableDebugMode}>
      <BugReport />
    </IconButton>;
  }

  return <Dialog className="about-dialog" open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth={true}>
    <DialogTitle sx={{userSelect: "none"}}>About</DialogTitle>
    <DialogContent>
      <div style={{display: "flex", flexDirection: "column"}}>
        {renderProductVersion("Web App", `v${Config.get("version")}`, Config.get("hash"))}
        {renderClientStatus()}
        {renderProductVersion("Server", `${serverStatus?.version ? `v${serverStatus.version}` : "(N/A)"}`, serverStatus?.hash ? serverStatus.hash : "(N/A)")}
      </div>
      <div style={{fontSize: "0.75em", textAlign: "center", paddingTop: "1em", color: "#808080"}}>
        Copyright &copy;2021-{new Date().getFullYear()} Drivepoint
      </div>
    </DialogContent>
    <DialogActions>
      {renderDebugButton()}
      <div className="about-dialog-spacer" />
      <ThemedDefaultButton onClick={() => setOpen(false)}>Done</ThemedDefaultButton>
    </DialogActions>
  </Dialog>;

});

export default AboutDialog;
