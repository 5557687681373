/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import "./Logo.less";

export type LogoProps = {
  className?: string;
  onClick?: (event: any) => void;
};

export default function Logo(props: LogoProps): any {

  function onClick(event: any): void {
    if (props.onClick) { props.onClick(event); }
  }

  const classes = ["logo"];
  if (props.onClick) { classes.push("clickable"); }

  return <div className={props.className ?? `${classes.join(" ")}`} onClick={onClick} />;

}
