/**
 * Copyright ©2022 Drivepoint
 */

import ITelemetryProvider from "./ITelemetryProvider";
import * as FS from "@fullstory/browser";
import {UserVars} from "@fullstory/browser";
import {Config} from "@bainbridge-growth/node-frontend";
import Telemetry from "@services/telemetry/Telemetry";

export default class FullStory implements ITelemetryProvider {

  constructor() {
    if (Config.get("fullstory.enabled")) {
      FS.init({orgId: Config.get("fullstory.id")});
    }
  }

  async embed(): Promise<void> {
  }

  async identify(): Promise<void> {
    const user = Telemetry.user;
    const company = Telemetry.company;
    if (user?.user?.email && user?.excelUser && company) {
      const uid = `${user.user.uid}-${Config.get("environment")}`;
      const payload: UserVars = {
        email: user.user.email,
        environment_str: Config.get("environment"),
        version_str: Config.get("version"),
        hash_str: Config.get("hash"),
        displayName: `${user.excelUser.firstName} ${user.excelUser.lastName}`,
        company_str: company.id
      };
      FS.identify(uid, payload);
    } else {
      FS.anonymize();
    }
  }

  get name(): string { return "fullstory"; }

  async track(event: string, data: any = {}): Promise<void> {
  }

}
