/**
 * Copyright ©2022 Drivepoint
 */

import State from "../state/State";

export enum CompanyDataChannel {
  ecommerce = "ecommerce",
  finance = "finance"
}

export enum CompanyDataEndpoint {
  api = "api",
  dwh = "dwh"
}

export type CompanyDataSource = {
  source: keyof typeof CompanyDataEndpoint;
  id?: string;
  channel?: keyof typeof CompanyDataChannel;
};

export type CompanyDataSourceMap = {
  [key: string]: CompanyDataSource;
};

export type CompanyDataChannelMap = {
  [key in keyof typeof CompanyDataChannel]?: CompanyDataSourceMap;
};

export enum FirestorePlanTier {
  free = "free",
  beta = "beta",
  tier1 = "tier1",
  tier2 = "tier2",
  tier3 = "tier3"
}

export default class CompanyService extends EventTarget {

  constructor() {
    super();
    State.register("user", this.onUserStateChange.bind(this));
  }

  private _rehydrateCompany() {
    const companyId = location.pathname.split("/")?.[1];
    this.setCompanyById(companyId);
  }

  async init(): Promise<void> {}

  onUserStateChange(event: any): void {
    if (!State.get("user")) {
      this.reset();
    }
    else {
      this._rehydrateCompany();
    }
  }

  getCompanyById(id?: string): any {
    return this.companies.find((company: any) => company.id === id);
  }

  setCompanyById(id?: string): void {
    if (id) {
      this.company = this.getCompanyById(id);
    }
  };

  reset(): void {
    this.company = undefined;
  }

  get company(): any {
    return State.get("company");
  }

  set company(value: any | undefined) {
    State.set("company", value);
  }

  get companies(): any[] {
    return State.get("user")?.companies || [];
  }

  hasAccessToTier(tiers?: string[]): boolean {
    if (!tiers?.length) { return true; }
    const permissions = tiers.map(tier => {
      if (tier.startsWith("!")) {
        return {tier: tier, allowed: this.company.tier !== tier.replace(/^!/, "")};
      } else {
        return {tier: tier, allowed: this.company.tier === tier};
      }
    });
    return !permissions.find(permission => !permission.allowed);
  }

  hasAdminAccess(): boolean {
    const user = State.get("user");
    const role = user?.excelUser?.role;
    return role === "admin" || role === "superAdmin";
  }

  /**
   * Get the channel map from the current company's dataEndpoints property, if any.
   */
  getCompanyChannelMap(): CompanyDataChannelMap {
    const company = this.company;
    return company?.dataEndpoints ?? {};
  }

  /**
   * Get all the sources for a given channel, such as ScorecardDataChannel.ecommerce.
   */
  getSourcesForChannel(channel: CompanyDataChannel): CompanyDataSource[] {
    const endpoints = this.getCompanyChannelMap();
    const sources: CompanyDataSourceMap = endpoints[channel] ?? {};
    return Object.keys(sources)
      .map<CompanyDataSource>((key: string): CompanyDataSource => ({source: sources[key]!.source, id: key, channel: channel}));
  }

  /**
   * Check to see if the company has a specific endpoint in any of its sources. For example, check to see if any of
   * the sources have an endpoint of CompanyDataEndpoint.dwh, which can be used to determine if the customer has been
   * set up in the DWH.
   * @param endpoint
   */
  hasEndpoint(endpoint: CompanyDataEndpoint): boolean {
    const channelMap: CompanyDataChannelMap = this.getCompanyChannelMap();
    const channels: CompanyDataChannel[] = Object.values(CompanyDataChannel);
    const sources: string[] = channels
      .map((channel: CompanyDataChannel) => Object.values(channelMap[channel] ?? {}).map((source: CompanyDataSource) => source.source))
      .flat();
    return sources.includes(endpoint);
  }

  /**
   * Get the only source for a given channel, such as ScorecardDataChannel.ecommerce.
   * If there are no sources, return defaultSource. If there is more than one source,
   * throw an Error.
   */
  getSourceForChannel(channel: CompanyDataChannel, defaultSource?: CompanyDataSource): CompanyDataSource | undefined {
    const sources = this.getSourcesForChannel(channel).filter(source => source.source);
    if (!sources.length) { return defaultSource; }
    if (sources.length > 1) { throw new Error(`Found too many sources for channel ${channel}`); }
    return sources[0];
  }

}
