/**
 * Copyright ©2024 Drivepoint
 */

import React, {useState} from "react";

import {Alert, Button, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {EmailAuthProvider, linkWithCredential} from "firebase/auth";

import Firebase from "@services/firebase/Firebase";
import DrivepointUser from "@services/user/DrivepointUser";
import {Props} from "mui-modal-provider/dist/types";
import {useForm} from "react-hook-form";

import useStateChange from "@hooks/useStateChange";

import "./LinkAccountDialog.css";

export type LinkAccountDialogProps = {
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
  open: boolean;
} & Props;

export default function LinkAccountDialog({onClose, onSuccess, onError, ...props}: LinkAccountDialogProps): any {

  const company = useStateChange<any>("company");
  const user = useStateChange<DrivepointUser>("user");

  const {register, handleSubmit} = useForm({
    defaultValues: {
      email: user?.user?.email,
      password: ""
    }
  });

  const [error, setError] = useState<any>();

  async function onSubmit(values: any): Promise<any> {
    setError(null);
    if (!company) { return setError("There doesn't appear to be a valid company."); }
    if (!Firebase.auth.currentUser) { return setError("There doesn't appear to be a valid user."); }
    try {

      const credential = EmailAuthProvider.credential(values.email, values.password);
      await linkWithCredential(Firebase.auth.currentUser, credential);
      close();
    } catch (error: any) {
      logger.debug(error);
      setError(error);
    }
  }

  function close(): void {
    if (onClose) { onClose(); }
  }

  function getErrorMessage(error: any): string {
    switch (error.code) {
      case 404: return "The source document could not be found.";
      case 409: return "That name is already in use.";
      case 428: return "The Sharepoint site could not be found.";
      default: return `An unexpected error occurred: "${error.message}"`;
    }
  }

  function renderAlert(): any {
    if (!error) { return; }
    const message = getErrorMessage(error);
    return <Alert severity="error">{message}</Alert>;
  }

  return <Dialog className="link-account-dialog" {...props}>
    {renderAlert()}
    <DialogTitle className="link-account-dialog-title">Link Email Account</DialogTitle>
    <DialogContent className="link-account-dialog-content">
      <div className="link-account-dialog-info">
        This will link your existing Google account to a standard Email account.
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="link-account-dialog-form">
        <TextField fullWidth type="email" label="Email" disabled {...register("email")} />
        <TextField fullWidth type="password" label="Password" autoFocus required {...register("password")} />
        <div className="link-account-dialog-actions">
          <Button onClick={close} variant="text" color="inherit">Cancel</Button>
          <div className="spacer" />
          <Button type="submit" variant="contained">Sign In</Button>
        </div>
      </form>
    </DialogContent>
  </Dialog>;

}
