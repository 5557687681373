/**
 * Copyright ©2022 Drivepoint
 */

import React, {useImperativeHandle, useState} from "react";
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import ThemedDefaultButton from "../widgets/styled/ThemedDefaultButton";
import DPButton from "@components/DPButton/DPButton";

export type ErrorDialogProps = {
  title?: string;
  close?: string;
  maxWidth?: Breakpoint;
};

export type ErrorDialogInterface = {
  show: (body: any, callback: () => any, title?: any) => void;
};

const ErrorDialog = React.forwardRef((props: ErrorDialogProps, ref: any): any => {

  useImperativeHandle(ref, () => ({
    show: (body: any, callback: () => void, title?: any) => {
      if (title) setTitle(title);
      setBody(body);
      setCallback(() => callback);
      setOpen(true);
    }
  }));

  const [title, setTitle] = useState<string>(props.title || "Error");
  const [close, setClose] = useState<string>(props.close || "Close");
  const [body, setBody] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [callback, setCallback] = useState<Function>();

  function onCallback(): void {
    if (callback) {
      setTimeout(() => callback());
    }
    setOpen(false);
  }

  return <Dialog open={open} onClose={() => {}} maxWidth={props.maxWidth || "sm"} fullWidth={true} sx={{zIndex: 2500}}>
    <DialogTitle sx={{userSelect: "none"}}>{title}</DialogTitle>
    <DialogContent>
      <Box>{body}</Box>
    </DialogContent>
    <Divider />
    <DialogActions sx={{justifyContent: "flex-start"}}>
      <Box sx={{flexGrow: "1"}} />
      <DPButton onClick={() => onCallback()} label={close} />
    </DialogActions>
  </Dialog>;

});

export default ErrorDialog;
