/**
 * Copyright ©2022 Drivepoint
 */

import {Menu, MenuProps, styled} from "@mui/material";

const BrandedMenu = styled(Menu)<MenuProps>(({theme}: { theme: any }) => {
  return {
    ".MuiPaper-root": {
      color: theme.palette.chrome.primary.color,
      backgroundColor: theme.palette.chrome.primary.background,
      backgroundImage: "none",
      border: `1px solid ${theme.palette.chrome.divider}`
    }
  };
});

export default BrandedMenu;
