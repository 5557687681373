/**
 * Copyright ©2023 Drivepoint
 */


export default class GoogleCharts {

  static async init(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.onload = () => {
        google.charts.load("current", {packages: ["corechart", "table", "bar", "charteditor", "line"]});
        google.charts.setOnLoadCallback(resolve);
      };
      script.src = "https://www.gstatic.com/charts/loader.js";
      document.head.append(script);
    });
  }

  static get google(): any {
    return google;
  }

  static get charts(): any {
    return google.charts;
  }

  static get visualization(): any {
    return google.visualization;
  }

}
