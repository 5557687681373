/**
 * Copyright ©2022 Drivepoint
 */

import {ListItemIcon, ListItemIconProps, styled} from "@mui/material";

const PrimaryIcon = styled(ListItemIcon)<ListItemIconProps>(({theme}: {theme: any}) => {
  return {
    svg: {
      fontSize: "1.5rem",
      color: theme.palette.chrome.primary.color
    }
  };
});

export default PrimaryIcon;
