/**
 * Copyright ©2022 Drivepoint
 */

import {AppBar, AppBarProps, styled} from "@mui/material";

const ThemedAppBar = styled(AppBar)<AppBarProps>(({theme}: {theme: any}) => {
  return {
    position: "relative",
    backgroundImage: "none",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.chrome.divider}`,
    backgroundColor: theme.palette.chrome.primary.background,
    color: theme.palette.chrome.primary.color,
    "&.hidden": {
      display: "none"
    },
    ".MuiToolbar-root": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      "&.mobile": {
        paddingLeft: "0",
        paddingRight: "0"
      }
    }
  };
});

export default ThemedAppBar;
