/**
 * Copyright ©2022 Dana Basken
 */

export class Event {

  constructor(public type: string) {
  }

}
