/**
 * Copyright ©2022 Drivepoint
 */

import * as ld from "launchdarkly-js-client-sdk";
import {Config} from "@bainbridge-growth/node-frontend";
import DrivepointUser from "../user/DrivepointUser";

export default class LaunchDarklyClient {

  static ANONYMOUS_USER = Config.get("launchdarkly.user.anonymous");

  static _client: ld.LDClient = ld.initialize(Config.get("launchdarkly.id"), LaunchDarklyClient.ANONYMOUS_USER);

  static async init(): Promise<void> {
    return LaunchDarklyClient.client.waitForInitialization();
  }

  static async identify(user: DrivepointUser, company?: any): Promise<void> {
    await LaunchDarklyClient.client.identify(LaunchDarklyClient.makeUser(user, company));
  }

  static makeUser(user: DrivepointUser, company?: any): ld.LDSingleKindContext {
    if (!user?.user?.email) { return LaunchDarklyClient.ANONYMOUS_USER; }
    return {
      kind: "user",
      key: user.user.email,
      email: user.user.email,
      firstName: user.excelUser.firstName,
      lastName: user.excelUser.lastName,
      company_id: company?.id ?? user.excelUser.excelCompanyId,
      role: user.excelUser.role,
      tier: company?.tier ?? "unknown"
    };
  }

  static async get<T>(key: string, defaultValue?: any): Promise<T> {
    return LaunchDarklyClient.client.variation(key, defaultValue) as T;
  }

  static get keys(): string[] {
    return Object.keys(LaunchDarklyClient.client.allFlags());
  }

  static async flags(): Promise<any> {
    const flags: any = {};
    for (const key of LaunchDarklyClient.keys) {
      flags[key] = await LaunchDarklyClient.get(key);
    }
    return flags;
  }

  static get client(): ld.LDClient {
    return LaunchDarklyClient._client;
  }

}
