/**
 * Copyright ©2022 Drivepoint
 */

import {styled, TextField, TextFieldProps} from "@mui/material";

const ThemedInputField = styled(TextField)<TextFieldProps>(({theme}: {theme: any}) => {
  return {
    margin: "0 0.25rem",
    flexGrow: "1",
    label: {
      color: theme.palette.content.primary.color,
      backgroundColor: "transparent",
      padding: "0 0.2rem",
      borderRadius: "0.2rem",
      "&.Mui-focused": {
        color: theme.palette.content.primary.color
      }
    },
    ".MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.5)"
    },
    ".MuiInputBase-root": {
      input: {
        WebkitTextFillColor: theme.palette.content.primary.color,
        width: "100%",
        webkitBoxShadow: "none",
        boxShadow: `0 0 0px 100px ${theme.palette.content.secondary.background} inset`
      },
      fieldset: {
        borderColor: theme.palette.content.border,
        borderWidth: "2px",
        "&.MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.content.border
        }
      }
    }
  };
});

export default ThemedInputField;
