import {ObjectUtilities} from "@bainbridge-growth/node-common";

export default class TemplateLibraryUtilities {

  // TemplateJSLibrary.setContextValue(this, "key", "value") sets $CONTEXT["key"] = value
  // TemplateJSLibrary.setContextValue(this, "key.sub_key", "value") sets $CONTEXT["key"]["sub_key"] = value
  static setContextValue(globals: any, key: string, value: any): void {
    if (!globals.$CONTEXT) { globals.$CONTEXT = {}; }
    ObjectUtilities.setDottedKeyValue(key, value, globals.$CONTEXT);
  }

  // TemplateJSLibrary.getContextValue(this, "key") gets $CONTEXT["key"]
  // TemplateJSLibrary.getContextValue(this, "key.sub_key") gets $CONTEXT["key"]["sub_key"]
  static getContextValue(globals: any, key: string, defaultValue?: any): any {
    if (!globals.$CONTEXT) { globals.$CONTEXT = {}; }
    return ObjectUtilities.getDottedKeyValue(key, globals.$CONTEXT, defaultValue);
  }

  // TemplateJSLibrary.setGlobalValue(this, "key", "value") sets $["key"] = value
  // TemplateJSLibrary.setGlobalValue(this, "key.sub_key", "value") sets $["key"]["sub_key"] = value
  static setGlobalValue(globals: any, key: string, value: any): void {
    if (!globals.$) { globals.$ = {}; }
    ObjectUtilities.setDottedKeyValue(key, value, globals.$);
  }

  // TemplateJSLibrary.getGlobalValue(this, "key") gets $["key"]
  // TemplateJSLibrary.getGlobalValue(this, "key.sub_key") gets $["key"]["sub_key"]
  static getGlobalValue(globals: any, key: string, defaultValue?: any): any {
    if (!globals.$) { globals.$ = {}; }
    return ObjectUtilities.getDottedKeyValue(key, globals.$, defaultValue);
  }

}
