/**
 * Copyright ©2022 Drivepoint
 */

import {Box, BoxProps, CircularProgress, styled} from "@mui/material";
import React from "react";

const DialogProgressBox = styled(Box)<BoxProps>(({theme}) => {
  return {
    position: "absolute",
    "inset": "0",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.25)"
  };
});

export default function DialogProgress(props: any): any {

  return <DialogProgressBox sx={{display: props.show ? "flex" : "none"}}>
    <CircularProgress />
  </DialogProgressBox>;

}
