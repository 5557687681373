/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";
import ITelemetryProvider from "./ITelemetryProvider";
import Segment from "./Segment";
import Appcues from "./Appcues";
import FullStory from "./FullStory";
import State from "@services/state/State";
import DrivepointUser from "@services/user/DrivepointUser";

/**
 * This is where we embed third-party site analysis products. We do this here to keep index.html less cluttered, and
 * allow for conditional loading of a product if needed.
 */

export default class Telemetry {

  private static _user: DrivepointUser;
  private static _company: any;

  static providers: ITelemetryProvider[] = [
    new Segment(),
    new Appcues(),
    new FullStory()
  ];

  static async init() {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { await provider.embed(); }
    }
    State.registerMany("user", "company", (event: any) => {
      switch (event.key) {
        case "user":
          Telemetry.user = event.value;
          break;
        case "company":
          Telemetry.company = event.value;
          break;
      }
      Telemetry.identify();
    });
  }

  static async identify() {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.identify(); }
    }
  }

  static async track(event: string, data: any = {}): Promise<void> {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.track(event, data); }
    }
  }

  static set user(value: DrivepointUser) {
    Telemetry._user = value;
  }

  static set company(value: any) {
    Telemetry._company = value;
  }

  static get user(): DrivepointUser {
    return Telemetry._user;
  }

  static get company(): any {
    return Telemetry._company;
  }

}
