/**
 * Copyright ©2022 Drivepoint
 */

import React, {useImperativeHandle, useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem
} from "@mui/material";
import ThemedDefaultButton from "../styled/ThemedDefaultButton";
import ThemedInputField from "../styled/ThemedInputField";
import ThemedSelect from "../styled/ThemedSelect";
import DialogProgress from "./DialogProgress";
import useStateChange from "@hooks/useStateChange";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import WebAppServerError from "../../errors/WebAppServerError";

type PageEditDialogProps = {
};

const PageEditDialog = React.forwardRef((props: PageEditDialogProps, ref: any): any => {

  const COMPONENTS: any[] = [
    {id: "DataStudioPage", name: "Embedded Link"},
    {id: "ExternalLinkPage", name: "External Link"}
  ];
  const EMPTY_PAGE: any = {name: "", section: "", component: "", url: ""};

  useImperativeHandle(ref, () => ({
    edit: (page?: any) => {
      setPage(page ?? {});
      setOpen(true);
    }
  }));

  const form = useRef<any>();
  const company = useStateChange<any>("company");

  const [open, setOpen] = useState<boolean>();
  const [page, setPage] = useState<any>(EMPTY_PAGE);
  const [working, setWorking] = useState<boolean>();
  const [errors, setErrors] = useState<any[]>();

  async function onSubmit(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    setErrors([]);
    setWorking(true);
    const errors = await save(page);
    setWorking(false);
    if (errors) {
      setErrors(errors);
      return;
    }
    setOpen(false);
  }

  async function save(page: any): Promise<any> {
    try {
      if (page.id) {
        await WebAppServerClient.updateCompanyPage(company?.id, page);
      } else {
        await WebAppServerClient.createCompanyPage(company?.id, page);
      }
    } catch (error: any) {
      if (error instanceof WebAppServerError) {
        return error.response.errors;
      } else {
        return [{error: error.message}];
      }
    }
  }

  function updatePage(key: string, value: any, event?: any): void {
    setPage(Object.assign({}, page, {[key]: value}));
  }

  function renderComponentItems(): any {
    return COMPONENTS?.map((component: any) => {
      return <MenuItem key={`company_${component.id}`} value={component.id}>{component.name}</MenuItem>;
    }) || [];
  }

  function renderTitle(): any {
    if (page?.id) {
      return `Edit page "${page.name}" for "${company?.name}"`;
    } else {
      return `Add page for "${company?.name}"`;
    }
  }

  function renderErrors(): any {
    if (errors?.length) {
      return <Box>
        <Divider />
        <Box sx={{margin: "0.5rem", fontSize: "0.9rem", color: "red"}}>
          {errors.map((error: any) => error.error).join(", ")}
        </Box>
      </Box>;
    }
  }

  return <Dialog open={!!open} onClose={() => {}} fullWidth={true} maxWidth="sm">
    <DialogProgress show={working} />
    <DialogTitle sx={{userSelect: "none"}}>{renderTitle()}</DialogTitle>
    <Box ref={form} component="form" onSubmit={onSubmit} sx={{display: "flex", flexDirection: "column", marginTop: "1rem"}}>
      <DialogContent sx={{padding: "0.25rem 1rem"}}>
        <ThemedInputField sx={{margin: "0 0 1rem 0", width: "100%"}} data-field="name" label="Name" value={page.name} onChange={event => updatePage("name", event.target.value, event)} />
        <ThemedSelect sx={{margin: "0 0 1rem 0"}} value={page.component} data-field="component" label="Type" onChange={event => updatePage("component", event.target.value)}>
          {renderComponentItems()}
        </ThemedSelect>
        <ThemedInputField sx={{margin: "0 0 1rem 0", width: "100%"}} data-field="url" label="URL" value={page.url} onChange={event => updatePage("url", event.target.value)} />
      </DialogContent>
      {renderErrors()}
      <Divider />
      <DialogActions sx={{display: "flex", flexDirection: "row", padding: "1rem", justifyContent: "flex-start"}}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Box sx={{flexGrow: "1"}} />
        <ThemedDefaultButton type="submit">Save</ThemedDefaultButton>
      </DialogActions>
    </Box>
  </Dialog>;

});

export default PageEditDialog;
