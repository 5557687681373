/**
 * Copyright ©2022 Drivepoint
 */

import {MenuItem, MenuItemProps, styled} from "@mui/material";

const ThemedMenuItem = styled(MenuItem)<MenuItemProps>(({theme}) => {
  return {
    width: "20rem"
  };
});

export default ThemedMenuItem;
