/**
 * Copyright ©2022 Drivepoint
 */

import {Select, SelectProps, styled} from "@mui/material";

const ThemedSelect = styled(Select)<SelectProps>(({theme}: {theme: any}) => {
  return {
    margin: "0 4px 1rem 4px",
    width: "100%",
    fieldset: {
      borderWidth: "2px",
      borderColor: `${theme.palette.content.border} !important`,
      legend: {
        height: "1.5rem"
      }
    },
    "&.Mui-focused": {
      fieldset: {
        legend: {
          span: {
            opacity: "1"
          }
        }
      }
    }
  };
});

export default ThemedSelect;
