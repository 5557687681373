/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import useStateChange from "@hooks/useStateChange";
import DrivepointUser from "@services/user/DrivepointUser";
import "./UserNameAndCompany.less";

export default function UserNameAndCompany(props: any): any {

  const company = useStateChange<any>("company");
  const user = useStateChange<DrivepointUser>("user");

  function getFirstNameAndInitial(): any {
    if (user?.excelUser?.firstName) {
      return <div className="name-and-initial">
        {`${user?.excelUser?.firstName} ${user?.excelUser?.lastName[0]}.`}
      </div>;
    }
  }

  function getCompany(): any {
    const name = company?.name ?? "";
    const classes = ["company-name"];
    if (company?.id !== user?.excelUser?.excelCompanyId) { classes.push("impersonating"); }
    return <div className={classes.join(" ")}>{name}</div>;
  }

  function getNameAndCompany(): any {
    if (user?.excelUser) {
      return <>
        {getFirstNameAndInitial()}
        {getCompany()}
      </>;
    }
  }

  return <div className="username-and-company">
    {getNameAndCompany()}
  </div>;
}
