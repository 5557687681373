/**
 * Copyright ©2022 Drivepoint
 */

const constants = {
  drawers: {
    navigation: {
      expandedWidth: "20rem",
      collapsedWidth: "3.5rem",
      indent: "0.5rem"
    },
    configuration: {
      width: "20rem"
    }
  },
  helpUrls: {
    amazonSPConnecting: "https://docs.drivepoint.io/help/v/data-integrations/connecting-amazon-seller-central",
    netsuiteConnecting: "https://docs.drivepoint.io/help/v/data-integrations/connecting-netsuite-financials"
  }
};

export default constants;
