/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useMemo, useState} from "react";
import {createTheme} from "@mui/material";
import {ThemeModes, themes, typography} from "../configuration/theme";
import Telemetry from "../services/telemetry/Telemetry";
import State from "../services/state/State";

const ThemeContext = React.createContext({
  toggle: () => {},
  mode: undefined,
  theme: undefined
});

export default function useThemeContext(): any {

  const initialMode: ThemeModes = localStorage.getItem("ThemeMode") as ThemeModes || ThemeModes.LIGHT;
  const [mode, setMode] = useState<ThemeModes>(initialMode);

  useEffect(() => {
    document.body.dataset.theme = mode;
    if (mode === ThemeModes.DARK) {
      document.body.classList.remove(ThemeModes.LIGHT);
      document.body.classList.add(ThemeModes.DARK);
    } else {
      document.body.classList.remove(ThemeModes.DARK);
      document.body.classList.add(ThemeModes.LIGHT);
    }
    State.set("theme", mode);
  }, [mode]);

  const theme = useMemo(() => {
    const palette = themes[mode];
    return createTheme({
      palette: {
        mode, ...palette
      },
      typography: {
        ...typography
      }
    });
  }, [mode]);

  const themeProvider = useMemo(() => ({
    toggle: () => {
      setMode((prevMode) => {
        const newMode = prevMode === ThemeModes.LIGHT ? ThemeModes.DARK : ThemeModes.LIGHT;
        Telemetry.track("change_theme", {theme: newMode});
        localStorage.setItem("ThemeMode", newMode);
        return newMode;
      });
    },
    mode: mode,
    theme: theme
  }), [mode]);

  return {themeProvider, ThemeContext, theme};

}
