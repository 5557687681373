/**
 * Copyright ©2024 Drivepoint */

import "reflect-metadata";

export type DazzlerMethodParameterOptions = {
  description?: string;
  type?: string;
};

export const DazzlerMethodParameter = (parameter: string, options: DazzlerMethodParameterOptions): any => {
  return (target: any, propertyKey: string): void => {
    let documentation: any = {};
    if (Reflect.hasMetadata("__DAZZLER_DOCUMENTATION__", target)) {
      documentation = Reflect.getMetadata("__DAZZLER_DOCUMENTATION__", target) as any;
    }
    if (!documentation[propertyKey]) { documentation[propertyKey] = {}; }
    documentation[propertyKey].namespace = target.NAMESPACE;
    if (!documentation[propertyKey].parameters) { documentation[propertyKey].parameters = []; }
    if (!documentation[propertyKey].parameters.find((it: any) => it.parameter === parameter)) {
      documentation[propertyKey].parameters.push({parameter, ...options});
      Reflect.defineMetadata("__DAZZLER_DOCUMENTATION__", documentation, target);
    }
  };
};
