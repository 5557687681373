/**
 * Copyright ©2022 Drivepoint
 */

import React, {JSX, useEffect, useState} from "react";
import {Toolbar} from "@mui/material";
import ThemedAppBar from "../widgets/styled/ThemedAppBar";
import useStateChange from "../hooks/useStateChange";
import DrivepointUser from "@services/user/DrivepointUser";

export default function TopAppBar(props: any): JSX.Element {

  const user = useStateChange<DrivepointUser>("user");
  const chrome = useStateChange<string>("chrome");

  const [hideChrome, setHideChrome] = useState<boolean>();

  useEffect(() => {
    setHideChrome(!user || chrome === "disabled");
  }, [user, chrome]);

  const classes = [`${hideChrome ? "hidden" : ""}`].join(" ");

  return <ThemedAppBar className={classes}  position="relative">
    <Toolbar className={classes}>
      {props.children}
    </Toolbar>
  </ThemedAppBar>;

}
