/**
 * Copyright ©2022 Drivepoint
 */

import React, {useImperativeHandle, useState} from "react";
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DPButton from "../DPButton/DPButton";
import "./YesNoDialog.less";

export type YesNoDialogProps = {
  title?: string;
  yes?: string;
  no?: string;
  maxWidth?: Breakpoint;
};

export type YesNoDialogInterface = {
  show: (body: any, callback: (result: any) => any) => void;
};

const YesNoDialog = React.forwardRef(({title = "Confirm", yes = "Yes", no = "No", maxWidth = "sm"}: YesNoDialogProps, ref: any): any => {

  useImperativeHandle(ref, () => ({
    show: (body: any, callback: (result: any) => void) => {
      setBody(body);
      setCallback(() => callback);
      setOpen(true);
    }
  }));

  const [body, setBody] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [callback, setCallback] = useState<Function>();

  function onCallback(result: any): void {
    if (callback) {
      setTimeout(() => callback(result));
    }
    setOpen(false);
  }

  return <Dialog className="yes-no-dialog" open={open} onClose={() => {}} maxWidth={maxWidth} fullWidth={true}>
    <DialogTitle className="yes-no-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <div>{body}</div>
    </DialogContent>
    <DialogActions className="yes-no-dialog-actions">
      <DPButton variant="text" type="secondary" label={no} onClick={() => onCallback("no")} />
      <div className="yes-no-dialog-button-spacer" />
      <DPButton label={yes} onClick={() => onCallback("yes")} />
    </DialogActions>
  </Dialog>;

});

export default YesNoDialog;
