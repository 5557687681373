/**
 * Copyright ©2022 Drivepoint
 */

import React, {JSX, ReactNode} from "react";
import useQueryParams from "@hooks/useQueryParams";
import PageContent from "../widgets/styled/PageContent";
import PageContainer from "../widgets/styled/PageContainer";
import "./Page.css";

interface PageProps {
  children: ReactNode;
  toolbar?: ReactNode;
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
}

export default function Page(props: PageProps): JSX.Element {

  const queryParams = useQueryParams();
  const puppeteer_report: boolean = queryParams.get("puppeteer_report")?.toLowerCase() === "true";

  return <PageContainer
    onContextMenu={props.onContextMenu}
    onClick={props.onClick}
    className="page-container"
    style={puppeteer_report ? {height: "auto"} : {}}
  >
    {props.toolbar}
    <PageContent className={`page-content ${props.className || ""}`}>{props.children}</PageContent>
  </PageContainer>;

}
