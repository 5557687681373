/**
 * Copyright ©2022 Drivepoint
 */

import {Paper, PaperProps, styled} from "@mui/material";

const PageContainer = styled(Paper)<PaperProps>(({theme}: {theme: any}) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  backgroundImage: "none",
  backgroundColor: theme.palette.content.primary.background
}));

export default PageContainer;
