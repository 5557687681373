/**
 * Copyright ©2023 Drivepoint
 */

import {Store} from "@bainbridge-growth/node-frontend";
import State from "../state/State";
import {StateEvent} from "../state/StateEvent";
import WebAppServerClient from "../clients/WebAppServerClient";

export default class ExcelCompanyStore extends Store {

  private _dataSources:Record<string, any>;

  init(): void {
    State.register("excel_company", (event: StateEvent) => {
      this.value = [];
      this.value = event.value;
      this._dataSources = {};
    });
  }

  async update(data: any): Promise<any> {
    return this.action(async () => {
      return await WebAppServerClient.request(`/ui/excelCompany/${this.value.id}`, "PATCH", data);
    });
  }

  async upgradeCompanyTier(tier: string) {
    return this.action(async () => {
      return await WebAppServerClient.request(`/ui/company/${this.value.id}/upgradeCompany`, "POST", {tier}, undefined, {keepalive: true});
    });
  }

  async getActiveDataSourcesForCompany(company_id: string): Promise<any> {
    return this.action(async () => {
      this._dataSources =  await WebAppServerClient.request(`/ui/company/${company_id}/activeDataSources`, "GET");
    });
  }

  get dataSources() {
    return this._dataSources;
  }

}
