/**
 * Copyright ©2023 Drivepoint
 */

/*
  How to add a function
  1. write your code n the Dazzler UI using a simple JS function
    function addActualsToGlobals() { do stuff;}
  2. you can assume that you have access to $ in the body, later on this will be instance variable this.GLOBALDATA.$
  3. it's better to pass in what you need however, especially if it's a subset like $.data or $.formatter
  4. it doesn't understand types in the dazzler UI so use Javascript
  5. get it working
  6. Add an "Entrypoint" function into this file addActualsToGlobalsEntrypoint
  7. add the name from your function in dazzler addActualsToGlobals to the "functions" registry and bind to globals.  have the fn: property point to your 'entrypoint' function ie addActualsToGlobalsEntrypoint
  8. add the addActualsToGlobalsEntrypoint function, copy the other examples, basically make an instance of, and call a function
  9. return the resulting this variable

  Open up TemplateJSLibraryFunctions.tx
  1. add your code from dazzler and change function addActualsToGlobals()-> addActualsToGlobals()
  2. add type hints ie :any (working to get TS enabled)
  3. this.GLOBALDATA will contain the globals from prior cells so this.GLOBALDATA.$ is a reference to that.  Mutate this as you need to
        be careful to de-structure, and not remove keys from this object
  4. invoke your code from the Entrypoint function, see TemplateJSLibrary.prepFinancialMetricsWithVarianceReportFilterEntrypoint
  5. return this.GLOBALDATA
  6. add unit tests for your newly added code

 */

import dayjs from "dayjs";
import TemplateJSLibraryFunctions from "@utilities/template/libraries/TemplateJSLibraryFunctions";
import TemplateLibraryUtilities from "@utilities/template/libraries/TemplateLibraryUtilities";
import {DazzlerMethodAbstract} from "../../../decorators/dazzler/DazzlerMethodAbstract";
import {DazzlerMethodParameter} from "../../../decorators/dazzler/DazzlerMethodParameter";
import {DazzlerMethodGroup, DazzlerMethodGroupName} from "../../../decorators/dazzler/DazzlerMethodGroup";

export default class TemplateJSLibrary {

  static NAMESPACE: string = "JS";

  static functions(globals: any): any[] {
    return [
      {name: "onError", fn: TemplateJSLibrary.onError.bind(globals)},
      {name: "setContextAndGlobalExample", fn: TemplateJSLibrary.setContextAndGlobalExample.bind(globals)},
      {name: "printfoo", fn: TemplateJSLibrary.printfoo.bind(globals)},
      {name: "addValues", fn: TemplateJSLibrary.addValues.bind(globals)},
      {name: "dayjs", fn: dayjs},
      {name: "prepFinancialMetricsWithVarianceReportFilter", fn: TemplateJSLibrary.prepFinancialMetricsWithVarianceReportFilter.bind(globals)},
      {name: "prepFinancialMetricsWithoutVarianceReportFilter", fn: TemplateJSLibrary.prepFinancialMetricsWithoutVarianceReportFilter.bind(globals)},
      {name: "renameSecondColumnToSomethingElse", fn: TemplateJSLibrary.renameSecondColumnToSomethingElse.bind(globals)},
      {name: "addActualsToGlobals", fn: TemplateJSLibrary.addActualsToGlobals.bind(globals)},
      {name: "formatterForSalesReportTable", fn: TemplateJSLibrary.formatterForSalesReportTable.bind(globals)},
      {name: "formatterForSalesReportChart", fn: TemplateJSLibrary.formatterForSalesReportChart.bind(globals)},
      {name: "buildMetricAsClause", fn: TemplateJSLibrary.buildMetricAsClause.bind(globals)},
      {name: "formatPivotedCohortData", fn: TemplateJSLibrary.formatPivotedCohortData.bind(globals)},
      {name: "formatterForCohortTable", fn: TemplateJSLibrary.formatterForCohortTable.bind(globals)},
      {name: "buildMetricCommentaryForMarkdown", fn: TemplateJSLibrary.buildMetricCommentaryForMarkdown.bind(globals)},
      {name: "buildMetricCommentaryForHTML", fn: TemplateJSLibrary.buildMetricCommentaryForHTML.bind(globals)},
      {name: "pivotForGraphingFinancialData", fn: TemplateJSLibrary.pivotForGraphingFinancialData.bind(globals)},
      {name: "generateConfigMetadataForFinancialData", fn: TemplateJSLibrary.generateConfigMetadataForFinancialData.bind(globals)},
      {name: "formatRedGreenForPositiveNegativeValues", fn: TemplateJSLibrary.formatRedGreenForPositiveNegativeValues.bind(globals)},
      {name: "getPlansData", fn: TemplateJSLibrary.getPlansData.bind(globals)},
      {name: "getBainbridgeUser", fn: TemplateJSLibrary.getBainbridgeUser.bind(globals)},
      {name: "formatColumnsAsModelDatePattern", fn: TemplateJSLibrary.formatColumnsAsModelDatePattern.bind(globals)},
      {name: "formatRowsAsModelDatePattern", fn: TemplateJSLibrary.formatRowsAsModelDatePattern.bind(globals)},
      // {name: "formatCellsAsModelDatePattern", fn: TemplateJSLibrary.formatCellsAsModelDatePattern.bind(globals)},
      {name: "formatElementsWithCustomFormat", fn: TemplateJSLibrary.formatElementsWithCustomFormat.bind(globals)},
      {name: "pivotFromChartFormatToTable", fn: TemplateJSLibrary.pivotFromChartFormatToTable.bind(globals)},
      {name: "pivotFromTableFormatToChart", fn: TemplateJSLibrary.pivotFromTableFormatToChart.bind(globals)},
      {name: "formatAxisMinAndMaxByMultiplier", fn: TemplateJSLibrary.formatAxisMinAndMaxByMultiplier.bind(globals)},
      {name: "addBlankRowsToFinancialMetrics", fn: TemplateJSLibrary.addBlankRowsToFinancialMetrics.bind(globals)},
      {name: "applyColorRange", fn: TemplateJSLibrary.applyColorRange.bind(globals)},
      {name: "applyColorRangeWithMidPoint", fn: TemplateJSLibrary.applyColorRangeWithMidPoint.bind(globals)},
      {name: "attachAdditionalPlanDataToPrimaryPlan", fn: TemplateJSLibrary.attachAdditionalPlanDataToPrimaryPlan.bind(globals)},
      {name: "setUpDefaultFormatStructureForAllTypes", fn: TemplateJSLibrary.setUpDefaultFormatStructureForAllTypes.bind(globals)},
      {name: "setUpDefaultFormatStructureForSingleType", fn: TemplateJSLibrary.setUpDefaultFormatStructureForSingleType.bind(globals)}

    ].map(el => ({...el, namespace: TemplateJSLibrary.NAMESPACE}));
  }

  static onError(error: any): any {
    const LINE_OFFSET: number = 13;
    TemplateLibraryUtilities.setGlobalValue(this, "__JS_ERROR__", {message: error.message, where: {line: 1, column: 1}});
    const line = error.stack?.split("\n")[1];
    if (!line) { return; }
    const match = line.match(/<anonymous>:(\d+?):(\d+)/);
    if (match) {
      TemplateLibraryUtilities.setGlobalValue(this, "__JS_ERROR__", {message: error.message, where: {line: parseInt(match[1]) - LINE_OFFSET, column: parseInt(match[2])}});
    }
  }

  static getFunctionCallerName() {
    // gets the text between whitespace for second part of stacktrace
    let e:any = new Error();
    return (e)?.stack?.match(/at (\S+)/g)[1]?.slice(3)?.replace("Object.","");
  }

  static setContextAndGlobalExample(...args: any[]): void {
    TemplateLibraryUtilities.setContextValue(this, "context_example", args);
    TemplateLibraryUtilities.setGlobalValue(this, "global_example", args);
  }

  static printfoo(a: string, b: string): any {
    return `function printfoo() {console.log("PRINTFOO: ${a}, ${b}")};`;
  }
  static addValues(a: string, b: string): any {
    return a + b;
  }

  @DazzlerMethodAbstract("For Plan vs Actual report or Variance Report types. \n REQUIRES the variable $.m_monthly_lastest_closed_financials_final.  \n Appends as an additional colum into $.data.  Assumes the X axis are metric names, the column of Metric names is called 'Metric' and the Y axis is not a date spine but rather named columns like Plan, etc.", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static addActualsToGlobals() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.addActualsToGlobalsEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  /*
    To call in Dazzler
      let renamed = $LIB.JS.renameSecondColumnToSomethingElse("Forecast");
      $.data = renamed.$.data //  here you want to replace data. likely the safest
      OR
      $={...$, ...renamed.$}; // you could also destructure and only replace data
   */
  @DazzlerMethodAbstract("For Plan vs Actual report or Variance Report types.  Update $.data and rename the second column to the passed value.  Assumes the X axis are metric names, the column of Metric names is called 'Metric' and the Y axis is not a date spine but rather named columns like Plan, etc..", {returns: "any"})
  @DazzlerMethodParameter("newName", {description: "Name to rename the second column to.", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static renameSecondColumnToSomethingElse(newName: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.renameSecondColumnToSomethingElseEntrypoint(newName);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  /*
    To call in Dazzler - here you want to
      let foo = $LIB.JS.prepFinancialMetricsWithoutVarianceReportFilter();
      $={...$, ...foo.$};
  */

  @DazzlerMethodAbstract("For Plan vs Actual report, builds the 'Actual'. \n REQUIRES $.m_monthly_lastest_closed_financials, $.metadata_from_sql.  \n Takes a section of M-Monthly (incomestatement, balancesheet, cashflowstatement, metrics, margins and gets it ready for reporting by  \n 1) replace DB Names with names from M-Monthly \n 2) put metrics in order based on M-Monthly \n 3) Add Blank Lines  \n 4) Format with Indent from M-Monthly  \n 5) Renames to actuals \n 6) saves result in $.m_monthly_lastest_closed_financials_final \n 7) Also aves $.formatter  ", {returns: "result stored in $.m_monthly_lastest_closed_financials_final"})
  @DazzlerMethodParameter("metric_filter_key", {description: "In the DWH what is the key from source table usually (m_monthly_latest_table_metadata_with_defaults) identyfing the tab you wish to display.  if using m-monthly this can be left blank, so defaults to 'monthly___', for wholesale data enter 'wholesale___' ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static prepFinancialMetricsWithVarianceReportFilter(metric_filter_key:any = "monthly___", addPath: boolean = false, includeIsActualToDataSet: boolean = false) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.prepFinancialMetricsWithVarianceReportFilterEntrypoint(metric_filter_key, addPath, includeIsActualToDataSet);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("For Plan vs Actual report, builds the 'Actual'.  \n REQUIRES $.data, $.metadata_from_sql.  \n Takes a section of M-Monthly (incomestatement, balancesheet, cashflowstatement, metrics, margins and gets it ready for reporting by  \n 1) replace DB Names with names from M-Monthly \n 2) put metrics in order based on M-Monthly \n 3) Add Blank Lines  \n 4) Format with Indent from M-Monthly  \n 5) saves result in $.data \n 6) Saves $.formatter  ", {returns: "result stored in $.data"})
  @DazzlerMethodParameter("metric_filter_key", {description: "In the DWH what is the key from source table usually (m_monthly_latest_table_metadata_with_defaults) identyfing the tab you wish to display.  if using m-monthly this can be left blank, so defaults to 'monthly___', for wholesale data enter 'wholesale___' ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static prepFinancialMetricsWithoutVarianceReportFilter(metric_filter_key:any = "monthly___", addPath: boolean = false, includeIsActualToDataSet: boolean = false) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.prepFinancialMetricsWithoutVarianceReportFilterEntrypoint(metric_filter_key, addPath, includeIsActualToDataSet);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("For Plan vs Actual (variance) report, adds a 2nd or 3rd data set to the data for multi plan comparisons. \n REQUIRES $.m_monthly_lastest_closed_financials, $.metadata_from_sql.  \n Takes a section of M-Monthly (incomestatement, balancesheet, cashflowstatement, metrics, margins and gets it ready for reporting by  \n 1) replace DB Names with names from M-Monthly \n 2) Uses order from already established $.data \n \n 3) Renames to specified column name \n 4) saves result in $.data  \n\n  Example: attachAdditionalPlanDataToPrimaryPlan(\"Metric\", \"planz\",\"scenario_2\", \"plans_2\")", {returns: "result stored in $.data"})

  @DazzlerMethodParameter("metric_key_name", {description: "Name of the Column that contains the Metrics, usually 'Metric'", type: "string"})
  @DazzlerMethodParameter("data_key_name", {description: "Name of the Column that contains the actual data, usually 'planz'", type: "string"})
  @DazzlerMethodParameter("additional_plan_data_key", {description: "Key referencing the taw data structure from Alasql for metric you want to append to the data set.  if stored in $.scenario_name_2 then pass scenario_name_2 ", type: "string"})
  @DazzlerMethodParameter("additional_plan_name", {description: "Name of the new key/column to generate, ex 'planz_2'", type: "string"})
  @DazzlerMethodParameter("metric_filter_key", {description: "In the DWH what is the key from source table usually (m_monthly_latest_table_metadata_with_defaults) identyfing the tab you wish to display.  if using m-monthly this can be left blank, so defaults to 'monthly___', for wholesale data enter 'wholesale___' ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)

  static attachAdditionalPlanDataToPrimaryPlan(metric_key_name:any, data_key_name:any, additional_plan_data_key:any, additional_plan_name:any, metric_filter_key:any = "monthly___") {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.attachAdditionalPlanDataToPrimaryPlanEntrypoint(metric_key_name, data_key_name, additional_plan_data_key, additional_plan_name, metric_filter_key);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter for the Sales Report Table.  Assumes date column of 'Date' or 'order_date' or 'DAY/WEEK/MONTH/QUARTER/YEAR' and dates along the Y axis with 'Product Title' or 'Product Category' dollar values in the table", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatterForSalesReportTable() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatterForSalesReportTableEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter for the Sales Report Chart. Assumes date column of 'Date' or 'order_date' or 'DAY/WEEK/MONTH/QUARTER/YEAR' and all values are in dollars ", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatterForSalesReportChart() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatterForSalesReportChartEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  /*
    To call a formatter in Dazzler
      let basicFormatters = $LIB.JS.formatterForCohortTable();
      $.formatter={...$.formatter, ...basicFormatters.$.formatter};
   */
  @DazzlerMethodAbstract("Update $.formatter for the Cohort Table.  Set the left date spine to months of the 'MMM-YY' format and all other values to percents", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatterForCohortTable() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatterForCohortTableEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  /*
    $.metricAsClause = $LIB.JS.buildMetricAsClause($CONTEXT.monthly_trend_report_metric_name_array)
   */
  @DazzlerMethodAbstract("Take an array of elements and build a comma separated clause for sql of the form '`A`', '`B`', '`C`' using backticks for Alasql. ", {returns: "any"})
  @DazzlerMethodParameter("metrics_array", {description: "Array of Metric Names.", type: "array"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static buildMetricAsClause(metrics_array: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      return functionLibraryInstance.buildMetricAsClauseEntrypoint(metrics_array);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
  }

  /*
    To Call in Dazzler
      let pivoted_cohort_data = $LIB.JS.formatPivotedCohortData("Cohort Month");
      $.data = pivoted_cohort_data.$.data;
   */
  @DazzlerMethodAbstract("For the Pivoted Cohort Table.  Replaces $ data and ensures the data is in the right order, and values off the bottom of the table are blanks and non zero. \n REQUIRES $.fpm to be an array of objects containing 'cohort_month and 'max_months_since_first_purchase'", {returns: "any"})
  @DazzlerMethodParameter("pivot_key", {description: "Name of the Month key ie 'Cohort Month.", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static formatPivotedCohortData(pivot_key: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatPivotedCohortDataEntrypoint(pivot_key);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  /*
    USAGE:
    let alertsTextObject = $LIB.JS.buildMetricCommentaryForMarkdown("Date");
    $.alertsText = {...$.alertsText, ... alertsTextObject.$.alertsText}
   */
  @DazzlerMethodAbstract("Inserts markdown into $.alertsText for all data in $data", {returns: "any"})
  @DazzlerMethodParameter("date_key", {description: "Name of date column to generate commentary for, works best for months as it calculates change over periods. ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.INTELLIGENCE)
  static buildMetricCommentaryForMarkdown(date_column_name: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);

      functionLibraryInstance.buildMetricCommentaryForMarkdownEntrypoint(date_column_name);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Inserts markdown into $.alertsHtml for all data in $data", {returns: "any"})
  @DazzlerMethodParameter("date_key", {description: "Name of date column to generate commentary for, works best for months as it calculates change over periods. ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.INTELLIGENCE)
  static buildMetricCommentaryForHTML(date_column_name: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.buildMetricCommentaryForHtmlCellEntrypoint(date_column_name);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodGroup(DazzlerMethodGroupName.PIVOTS)
  static pivotForGraphingFinancialData() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);

      functionLibraryInstance.pivotForGraphingFinancialDataEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Pivot the default $.data from table-oriented to chart-oriented.  see: pivotFromChartFormatToTableEntrypoint  this does the inverse", {returns: "any"})
  @DazzlerMethodParameter("pivot_key", {description: "The ID of the column to pivot on", type: "any"})
  @DazzlerMethodParameter("new_first_column_key", {description: "The ID of the new first column", type: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.PIVOTS)
  static pivotFromTableFormatToChart(pivot_key: any, new_first_column_key: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.pivotFromTableFormatToChartEntrypoint(pivot_key, new_first_column_key);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract(`
Pivot the default $.data from chart-oriented to table-oriented. USAGE:

        $LIB.JS.pivotFromChartFormatToTable("Date", "Metric");

ie. this is a no-op

        $LIB.JS.pivotFromChartFormatToTable("Date", "Metric");
        $LIB.JS.pivotFromTableFormatToChart("Metric", "Date");

PIVOTS the format typically used for GRAPHING to A TABLE:

FROM:

    [
        {
            "Date": "2023-02-01",
            "Gross Sales DTC": 574470.88,
            "Gross Sales Marketplace": 516021.97,
            "Gross Sales Retail": 0,
            "Gross Sales Wholesale": 72032.88
        },
        {
        "Date": "2023-03-01",
             "Gross Sales DTC": 697745.61,
             "Gross Sales Marketplace": 610771.44,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 69486.53
         },
         {
             "Date": "2023-04-01",
             "Gross Sales DTC": 670277.71,
             "Gross Sales Marketplace": 603713.4,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 49526.8
         },
         {
             "Date": "2023-05-01",
             "Gross Sales DTC": 647968.69,
             "Gross Sales Marketplace": 633734.11,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 69750.04
         },
         {
             "Date": "2023-06-01",
             "Gross Sales DTC": 651843.58,
             "Gross Sales Marketplace": 632797.43,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 62205.02
         },
         {
             "Date": "2023-07-01",
             "Gross Sales DTC": 657822.33,
             "Gross Sales Marketplace": 697697.77,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 65901.94
         },
         {
             "Date": "2023-08-01",
             "Gross Sales DTC": 560948.63,
             "Gross Sales Marketplace": 530548.97,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 1003045.64
         },
         {
             "Date": "2023-09-01",
             "Gross Sales DTC": 478987.08,
             "Gross Sales Marketplace": 474481.7,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 54873.88
         },
         {
             "Date": "2023-10-01",
             "Gross Sales DTC": 493853.16,
             "Gross Sales Marketplace": 575572.73,
             "Gross Sales Retail": 0,
             "Gross Sales Wholesale": 30585.18
         }
     ]

TO:

     [
       {
         Metric: "Gross Sales DTC",
         "2023-02-01": 574470.88,
         "2023-03-01": 697745.61,
         "2023-04-01": 670277.71,
         "2023-05-01": 647968.69,
         "2023-06-01": 651843.58,
         "2023-07-01": 657822.33,
         "2023-08-01": 560948.63,
         "2023-09-01": 478987.08,
         "2023-10-01": 493853.16
       },
       {
         Metric: "Gross Sales Marketplace",
         "2023-02-01": 516021.97,
         "2023-03-01": 610771.44,
         "2023-04-01": 603713.4,
         "2023-05-01": 633734.11,
         "2023-06-01": 632797.43,
         "2023-07-01": 697697.77,
         "2023-08-01": 530548.97,
         "2023-09-01": 474481.7,
         "2023-10-01": 575572.73
       },
       {
         Metric: "Gross Sales Retail",
         "2023-02-01": 0,
         "2023-03-01": 0,
         "2023-04-01": 0,
         "2023-05-01": 0,
         "2023-06-01": 0,
         "2023-07-01": 0,
         "2023-08-01": 0,
         "2023-09-01": 0,
         "2023-10-01": 0
       },
       {
         Metric: "Gross Sales Wholesale",
         "2023-02-01": 72032.88,
         "2023-03-01": 69486.53,
         "2023-04-01": 49526.8,
         "2023-05-01": 69750.04,
         "2023-06-01": 62205.02,
         "2023-07-01": 65901.94,
         "2023-08-01": 1003045.64,
         "2023-09-01": 54873.88,
         "2023-10-01": 30585.18
       }
    ]
  `, {returns: "any"})
  @DazzlerMethodParameter("pivot_key", {description: "The ID of the column to pivot on ie 'Date' or 'MONTH'?", type: "any"})
  @DazzlerMethodParameter("new_first_column_key", {description: "where do you want the keys from the object to end up in the pivoted report, ie whats the column header for the new column 0? \"Metric\" \"Plan\" ,etc..", type: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.PIVOTS)
  static pivotFromChartFormatToTable(pivot_key: any, new_first_column_key: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.pivotFromChartFormatToTableEntrypoint(pivot_key, new_first_column_key);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter for the financial statements out of M-Monthly to \n 1) indent \n 2) bold", {returns: "any"})
  @DazzlerMethodParameter("metadata_from_sql", {description: "Query results of metadata from M-Monthly", type: "array"})
  @DazzlerMethodParameter("Metric type", {description: "one of 'incomestatement', 'balancesheet', 'cashflowstatement', 'metrics' or 'margins' ", type: "string"})
  @DazzlerMethodParameter("metric_filter_key", {description: "In the DWH what is the key from source table usually (m_monthly_latest_table_metadata_with_defaults) identyfing the tab you wish to display.  if using m-monthly this can be left blank, so defaults to 'monthly___', for wholesale data enter 'wholesale___' ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static generateConfigMetadataForFinancialData(metadata_from_sql: any, metric_type: any, metric_filter_key:any = "monthly___") {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.generateConfigMetadataForFinancialDataEntrypoint(metadata_from_sql, metric_type, metric_filter_key);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter for the Pivoted Cohort Table and add red or green numbers based on positive or negative numbers.", {returns: "any"})
  @DazzlerMethodParameter("metric_to_gradient", {description: "The ID of the column to apply color gradient", type: "string"})
  @DazzlerMethodParameter("first_row_key", {description: "The ID of the first row", type: "string"})
  @DazzlerMethodParameter("type", {description: "The type of data (number, date, currency, percent)", type: "string"})
  @DazzlerMethodParameter("decimals", {description: "The number of decimal places", type: "number"})
  @DazzlerMethodParameter("grouping", {description: "Show grouping separators?", type: "boolean"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatRedGreenForPositiveNegativeValues(metric_to_gradient: any, first_row_key: any, type: any, notation: any, decimals: number, grouping: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatRedGreenForPositiveNegativeValuesEntrypoint(metric_to_gradient, first_row_key, type, notation, decimals, grouping);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }
  //TODO: getPlansData() is hardcoded to madrabbit now
  @DazzlerMethodAbstract("get an array of plans data for this customer", {returns: "array"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static async getPlansData() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      await functionLibraryInstance.getPlansDataEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("get an array of users for this customer", {returns: "array"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static async getBainbridgeUser() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      await functionLibraryInstance.getBainbridgeUserEntrypoint();

    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter to a date pattern for a date column", {returns: "any"})
  @DazzlerMethodParameter("element_name", {description: "The date column ie 'MONTH", type: "string"})
  @DazzlerMethodParameter("pattern", {description: "date pattern as defined by day.js spec at https://day.js.org/docs/en/display/format ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatColumnsAsModelDatePattern(element_name: any,  pattern = "MMM-YY") {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatColumnsAsModelDatePatternEntrypoint(element_name, pattern);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.formatter to a date pattern for a date row", {returns: "any"})
  @DazzlerMethodParameter("element_name", {description: "The date row ie 'MONTH", type: "string"})
  @DazzlerMethodParameter("pattern", {description: "date pattern as defined by day.js spec at https://day.js.org/docs/en/display/format ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatRowsAsModelDatePattern(element_name: any,  pattern = "MMM-YY") {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatRowsAsModelDatePatternEntrypoint(element_name, pattern);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  // TODO: implement if needed... implementation not completed
  // @DazzlerMethodAbstract("Update $.formatter to a date pattern for a date cell", {returns: "any"})
  // @DazzlerMethodParameter("element_name", {description: "The date cell ie 'MONTH", type: "string"})
  // @DazzlerMethodParameter("pattern", {description: "date pattern as defined by day.js spec at https://day.js.org/docs/en/display/format ", type: "string"})
  // @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  // static formatCellsAsModelDatePattern(element_name: any,  pattern = "MMM-YY") {
  //   const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
  //   functionLibraryInstance.formatCellsAsModelDatePatternEntrypoint(element_name, pattern);
  //   return this;
  // }

  @DazzlerMethodAbstract(`
Update $.formatter safely. This will append formatting to any elements that already exist, not create them.
This replicates \`formatColumnsAsModelDatePattern("Date", "MMM YY")\`
    $LIB.JS.formatElementsWithCustomFormat("Date", "datePattern" ,"column", "MMM YY DD");
    $LIB.JS.formatElementsWithCustomFormat("Date", "type" ,"column", "date");
  `, {returns: "any"})
  @DazzlerMethodParameter("element_name", {description: "the actual name of the cell/column/row you want to change here", type: "string"})
  @DazzlerMethodParameter("custom_property", {description: "the property to override", type: "string"})
  @DazzlerMethodParameter("element_type", {description: "use 'row' OR 'column'", type: "string"})
  @DazzlerMethodParameter("value", {description: "Regexp or formatting value. If a date use the dayjs spec https://day.js.org/docs/en/display/format ", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatElementsWithCustomFormat(element_name: any, custom_property: any, element_type: any, value: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatElementsWithCustomFormatEntrypoint(element_name, custom_property, element_type, value);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract(`
For charts that are line and bar, where you want to float the line chart above the bar chart by manipulating the left and right axes min/max. This will update \`$.formatter\`.
**EXAMPLES**
- Move the left axis maximum higher, making the bar chart sit lower.
        formatAxisMinAndMaxByMultiplier("left", 1, 1.5,"Total Marketing Expenses DTC", false, false);
- Move the right axis to go zero to maximum value, makes it sit higher.
        formatAxisMinAndMaxByMultiplier("right", 1, 1,"Fully Loaded CAC DTC",0, false);
  `, {returns: "any"})
  @DazzlerMethodParameter("axisSide", {description: "The axis side to manipulate, 'left' or 'right'", type: "string"})
  @DazzlerMethodParameter("minMultiplier", {description: "multiply the minimum value by this number and set the minimum of the axis to this. ", type: "string"})
  @DazzlerMethodParameter("maxMultiplier", {description: "multiply the minimum value by this number and set the maximum of the axis to this.", type: "string"})
  @DazzlerMethodParameter("metricKey", {description: "what metric are we graphing, and using to calculate min/max ", type: "string"})
  @DazzlerMethodParameter("min_override", {description: "override of min, ie you can set to 0, -100 etc..", type: "string"})
  @DazzlerMethodParameter("max_override", {description: "override of max, ie you can set to 0, 100, etc", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static formatAxisMinAndMaxByMultiplier(axisSide: any, minMultiplier: any, maxMultiplier: any, metric_key: any, min_override: any, max_override: any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.formatAxisMinAndMaxByMultiplierEntrypoint(axisSide, minMultiplier, maxMultiplier, metric_key, min_override, max_override);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Update $.date to have blank lines to make financial statements look better.  Based on Drivepoint standard M-Monthly configuration.", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.GENERAL)
  static addBlankRowsToFinancialMetrics() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.addBlankRowsToFinancialMetricsEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Apply gradient color(s) to columns that have value type: number", {returns: "any"})
  @DazzlerMethodParameter("formatter", {description: "ability to apply additional formatter properties", type: "object"})
  @DazzlerMethodParameter("columns", {description: "For what columns apply gradient. By default all column", type: "string[]"})
  @DazzlerMethodParameter("colors", {description: "Bucket colors. Accept HEX code", type: "string[]"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static applyColorRange(colors: string[], columns: string[] = [], formatter:Record<string, any> = {}) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.handleColorRange(colors, columns, formatter);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Apply gradient color(s) to the table with mid point", {returns: "any"})
  @DazzlerMethodParameter("formatter", {description: "ability to apply additional formatter properties", type: "object"})
  @DazzlerMethodParameter("excludeCols", {description: "For what columns not to apply gradient.", type: "string[]"})
  @DazzlerMethodParameter("includeCols", {description: "For what columns apply gradient. By default all column", type: "string[]"})
  @DazzlerMethodParameter("mid_point", {description: "Value where the transition between the two colors happen.", type: "integer"})
  @DazzlerMethodParameter("colors", {description: "Bucket colors. Accept HEX code", type: "[start: string, mid: string, end: string]"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static applyColorRangeWithMidPoint(
    colors: [start: string, mid: string, end: string],
    mid_point:number = 0,
    includeCols: string[] = [],
    excludeCols: string[] = [],
    formatter:Record<string, any> = {}
  ) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.handleColorRangeWithMidPoint(colors, includeCols, excludeCols, mid_point, formatter);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Safely init formatters object if you are going to set formatters in js code locally", {returns: "any"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static setUpDefaultFormatStructureForAllTypes() {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.setUpDefaultFormatStructureForAllTypesEntrypoint();
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

  @DazzlerMethodAbstract("Safely init just a single formatters object if you are going to set formatters in js code locally", {returns: "any"})
  @DazzlerMethodParameter("format_type", {description: "type of Formater, one of rows, columns, cells, annotations, dataPoints, headers, vAxes", type: "string"})
  @DazzlerMethodGroup(DazzlerMethodGroupName.FORMATTERS)
  static  setUpDefaultFormatStructureForSingleType(format_type:any) {
    try {
      const functionLibraryInstance = new TemplateJSLibraryFunctions(this);
      functionLibraryInstance.setUpDefaultFormatStructureForSingleTypeEntrypoint(format_type);
    } catch (e) {
      throw new Error(`${TemplateJSLibrary.getFunctionCallerName()} threw an error: ` + e.message);
    }
    return this;
  }

}
