/**
 * Copyright ©2023 Drivepoint
 */

import React, {useEffect} from "react";
import {Toast, toast, Toaster} from "react-hot-toast";
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import {EventBus} from "@services/eventbus/EventBus";
import ComponentRegistry from "@services/ComponentRegistry";
import "./DPNotifications.less";

/*
  Sending a Toast from within the webapp:

  Create an object that describes a Toast event:

  const event: any = {
    "type": "toast",
    "toast": {
      "title": "Loading",
      "subtitle": "We are processing data, please stand by...",
      "severity": "info"
    }
  };

  for the "toast" object:
    setting "sticky" to true means it won't go away automatically, and will have a "CLOSE" button attached
    "duration" is how long before the Toast is auto-closed in milliseconds, it defaults to 5000
    "severity" can be one of ["info", "warning", "error"]
    "id" can be specified, using the same "id" in multiple dispatches will update the existing Toast, if it's still visible, otherwise will show a new Toast

  Call EventBus.dispatch(event) to deliver the event, which will cause the Toast to be displayed.
 */

export default function DPNotifications(props: any): any {

  useEffect(() => {
    const registration = EventBus.register("toast", async (event: any) => {
      if (event.toast) {
        const data = event.toast;
        toast.custom((t) => renderToast(t, data), {
          duration: data.sticky === true ? Number.POSITIVE_INFINITY : data.timeout || 5000,
          id: data.id,
          icon: undefined
        });
      }
    });
    return () => {
      EventBus.unregister(registration);
    };
  }, []);

  function renderCloseButton(t: Toast, data: any): any {
    if (data.sticky) {
      return <Box sx={{height: "100%"}}>
        <Button className="toast-button" data-toast-id={t.id} onClick={() => toast.remove(t.id)}>{data.close_label ?? "CLOSE"}</Button>
      </Box>;
    }
  }

  function renderSubTitle(data: any): any {
    if (data.subtitle) {
      return <Typography className="toast-subtitle" variant="subtitle1" component="div">{data.subtitle}</Typography>
    }
  }

  function renderIcon(t: Toast, data: any): any {
    if (data.icon) {
      if (typeof data.icon === "string") { data.icon = ComponentRegistry.classForName(data.icon); }
      return <data.icon className="toast-icon" />;
    }
  }

  function severityClass(baseClass: string, data: any): string {
    const classes = [baseClass];
    if (data?.severity) { classes.push(`toast-severity-${data.severity}`); }
    return classes.join(" ");
  }

  function renderToast(t: Toast, data: any): any {
    return <Card className={severityClass("toast-card", data)}>
      <Box className={severityClass("toast-card-box", data)}>
        {renderIcon(t, data)}
        <CardContent className="toast-card-content">
          <Typography className="toast-title" component="div" variant="h5">{data.title}</Typography>
          {renderSubTitle(data)}
        </CardContent>
        {renderCloseButton(t, data)}
      </Box>
    </Card>;
  }

  return <div className="toasts toaster">
    <Toaster />
  </div>;

}
