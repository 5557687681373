/**
 * Copyright ©2022 Drivepoint
 */

import {Button, ButtonProps, styled} from "@mui/material";

const ThemedDefaultButton = styled(Button)<ButtonProps>(({theme}: {theme: any}) => {
  return {
    backgroundColor: theme.palette.chrome.link.color,
    color: theme.palette.chrome.primary.color,
    padding: "0.5rem 1.5rem",
    "&:hover": {
      backgroundColor: theme.palette.chrome.link.hover
    }
  };
});

export default ThemedDefaultButton;
