/**
 * Copyright ©2022 Drivepoint
 */

import {Divider, DividerProps, styled} from "@mui/material";

const BrandedDivider = styled(Divider)<DividerProps>(({theme}: { theme: any }) => {
  return {
    borderColor: theme.palette.chrome.divider
  };
});

export default BrandedDivider;
