/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";
import {EventBus} from "../eventbus/EventBus";
import State from "../state/State";
import Firebase from "../firebase/Firebase";
import {StateEvent} from "../state/StateEvent";

export default class ServerSentEventService extends EventTarget {

  private static _eventSource?: EventSource;
  private _companyId?: string;

  async init(): Promise<void> {
    State.register("company", async (event: StateEvent) => {
      this._companyId = event?.value?.id;
      await this.reconnect();
    });
    State.register("firebase_token", async (event: StateEvent) => {
      // only reconnect if token was updated (usually due to token expiration)
      if (event.value && event.previous) { await this.reconnect(); }
    });
  }

  async connect(): Promise<void> {
    try {
      const token = Firebase.token;
      if (!token) { return; }
      if (this._companyId) {
        ServerSentEventService._eventSource = new EventSource(`${Config.get("server.api.url")}/ui/event/${this._companyId}?token=${token}`);
      } else {
        ServerSentEventService._eventSource = new EventSource(`${Config.get("server.api.url")}/ui/event?token=${token}`);
      }
      this.addListeners();
    } catch (error: any) {
      logger.error(error.message);
    }
  }

  onMessage(event: any): void {
    const data = JSON.parse(event.data);
    // slight hack: if the event is a state change, make sure State knows the new value, which will in turn trigger an EventBus.dispatch if needed
    const match = data.type?.match(/^state:(.+)$/);
    if (match) {
      State.set(match[1], data.value);
    } else {
      EventBus.dispatch(data);
    }
  }

  onOpen(): void {
    State.set("sse", "connected");
  }

  async onError(): Promise<void> {
    logger.trace("onError");
  }

  async disconnect(): Promise<void> {
    this.reset();
    State.set("sse", "disconnected");
  }

  async reconnect(delayMs?: number): Promise<void> {
    await this.disconnect();
    if (delayMs !== undefined) {
      setTimeout(() => { this.connect(); }, delayMs);
    } else {
      await this.connect();
    }
  }

  reset(): void {
    this.removeListeners();
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.close();
      ServerSentEventService._eventSource = undefined;
    }
  }

  addListeners(): void {
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.addEventListener("message", this.onMessage.bind(this));
      ServerSentEventService._eventSource.addEventListener("open", this.onOpen.bind(this));
      ServerSentEventService._eventSource.addEventListener("error", this.onError.bind(this));
    }
  }

  removeListeners(): void {
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.removeEventListener("message", this.onMessage.bind(this));
      ServerSentEventService._eventSource.removeEventListener("open", this.onOpen.bind(this));
      ServerSentEventService._eventSource.removeEventListener("error", this.onError.bind(this));
    }
  }

}
