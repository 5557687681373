/**
 * Copyright ©2022 Drivepoint
 */

import React, {Suspense, useEffect, useState} from "react";
import {SnackbarProvider} from "notistack";
import {useNavigate} from "react-router-dom";
import {Box, ThemeProvider} from "@mui/material";
import useThemeContext from "@hooks/useThemeContext";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import DPNotifications from "@components/DPNotifications/DPNotifications";
import BreadcrumbManager from "@services/BreadcrumbManager";
import usePage from "@hooks/usePage";
import useStateChange from "@hooks/useStateChange";
import DrivepointUser from "@services/user/DrivepointUser";
import {FirebaseAuthenticationState} from "@services/firebase/Firebase";
import {useLocalStorage, Config, Logger} from "@bainbridge-growth/node-frontend";
import {LoggerLevelFromString} from "@bainbridge-growth/node-frontend/src/logger/LoggerLevel";
import Suspended from "../../widgets/component/Suspended";
import UserBox from "../../widgets/component/UserBox/UserBox";
import VersionUpdatedButton from "../../widgets/component/VersionUpdatedButton";
import TopAppBar from "../TopAppBar";
import NavigationDrawer from "../navigation/NavigationDrawer/NavigationDrawer";
import Routing from "../Routing/Routing";
import DPErrorBoundary from "../DPErrorBoundary";
import "./App.less";

export default function App(): any {

  const defaultLoggerLevel = LoggerLevelFromString(Config.get("logger.level"));

  const {themeProvider, ThemeContext, theme} = useThemeContext();
  const page = usePage();
  const user = useStateChange<DrivepointUser>("user");
  const authentication = useStateChange<FirebaseAuthenticationState>("authentication");
  const navigate = useNavigate();
  const [loggerLevel] = useLocalStorage<number>(`${Config.get("environment")}.logger.level`, defaultLoggerLevel);

  const [navigationOpen, setNavigationOpen] = useState<boolean>(getNavigationDrawerState());

  useEffect(() => {
    if (loggerLevel != null) { Logger.level = Logger.level = loggerLevel; }
  }, [loggerLevel]);

  useEffect(() => {
    setNavigationDrawerState();
  }, [navigationOpen]);

  function getNavigationDrawerState(): boolean {
    const state = localStorage.getItem("navigationDrawerState");
    return state != null ? JSON.parse(state) : true;
  }

  function setNavigationDrawerState(): void {
    localStorage.setItem("navigationDrawerState", JSON.stringify(navigationOpen));
  }

  function toggleNavigationDrawer(): void {
    setNavigationOpen(!navigationOpen);
  }

  function renderAppBar(): any {
    if (page.current?.hideChrome) { return; }
    return <TopAppBar open={navigationOpen}>
      <Breadcrumbs ref={BreadcrumbManager.ref} />
      <Box sx={{flexGrow: 1}} />
      <VersionUpdatedButton />
      <UserBox />
    </TopAppBar>;
  }

  function renderNavigationDrawer(): any {
    if (page.current?.hideChrome) { return; }
    return <NavigationDrawer open={navigationOpen} toggle={toggleNavigationDrawer} resizable />;
  }

  if (authentication === FirebaseAuthenticationState.AUTHENTICATED && !user?.companies?.length && !page.current?.public) {
    navigate("/quickstart", {replace: true});
    return;
  }

  return <DPErrorBoundary fallback={<p>Here will be error component</p>}>
    <ThemeContext.Provider value={themeProvider}>
      <ThemeProvider theme={theme}>
        <main id="main" className="app desktop">
          {renderNavigationDrawer()}
          <section className="app-content">
            {renderAppBar()}
            <Suspense fallback={<Suspended />}>
              <SnackbarProvider preventDuplicate maxSnack={10}>
                <Routing />
              </SnackbarProvider>
            </Suspense>
          </section>
        </main>
        <DPNotifications />
      </ThemeProvider>
    </ThemeContext.Provider>
  </DPErrorBoundary>;

}
